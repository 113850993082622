<template>
  <of-modal narrow @closeModal="closeModal">
    <!-- Modal header title -->
    <template v-slot:modalHeaderTitle>
      <span class="opacity-50">
        {{ this.communication.collectionName }} / {{ this.eventTypeName }}
      </span>
      / Betalingsservice
    </template>

    <!-- Modal header right -->
    <template v-slot:modalHeaderRight>
      <of-button
        sm
        transparent
        class="mr-6"
        :icon="'code'"
        @click="this.showVariables = !this.showVariables"
      >
        Variables
      </of-button>
      <of-button
        @click="saveBs"
        :loading="this.savingEmail"
        :icon="this.saveEmailButtonIcon"
        sm
        class="ml-auto mr-0"
      >
        {{ this.saveButtonText }}
      </of-button>
    </template>

    <!-- Modal content -->
    <template v-slot:modalContent>
      <div class="preview-container-fluid relative h-full">
        <p class="mt-6">Text</p>
        <of-input-textarea v-model="this.bsText" style="min-height: 300px" />
        <app-variables-modal
          v-if="this.showVariables"
          @closeVariablesModal="closeVariablesModal"
        >
        </app-variables-modal>
      </div>
    </template>
  </of-modal>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapActions } from "vuex";
import AppVariablesModal from "../../AppVariablesModal.vue";
import toast from "../../../../../helpers/toast";
import eventTypes from "../../../../../helpers/communications/eventTypes";

export default {
  name: "AppEventTemplateBsEditor",
  components: {
    AppVariablesModal,
  },
  props: {
    communication: {
      type: Object,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      savingEmail: false,
      saveEmailButtonText: "Save",
      saveEmailButtonIcon: "",
      showVariables: false,
      bsText: this.template.bsText,
      phoneNumberTestingTextMessage: "",
      errorMessage: "",
    };
  },
  setup() {
    // Setup toast notification from '/src/helpers/toast.js'
    const { toastError, toastSuccess } = toast();
    return { toastError, toastSuccess };
  },
  computed: {
    /**
     * Save button text
     */
    saveButtonText() {
      if (this.savingEmail) {
        return "Saving";
      }

      return this.saveEmailButtonText;
    },

    /**
     * Get event type name
     */
    eventTypeName() {
      return eventTypes.find((x) => x.eventType === this.template.eventType)
        .name;
    },
  },
  emits: ["closeModal"],
  methods: {
    // Map actions (./store/modules/communications/templates/actions.js)
    ...mapActions({ updateTemplate: "communications/templates/update" }),

    /**
     * Save
     */
    saveBs() {
      // Start spinner and button text
      this.savingEmail = true;

      // The values to update
      const updateInTemplate = {
        communicationCollectionGuid: this.template.communicationCollectionGuid,
        communicationTemplateGuid: this.template.communicationTemplateGuid,
        bsText: this.bsText,
      };

      // Call update template action
      this.updateTemplate(updateInTemplate)
        .then(() => {
          // Notify user of success
          this.toastSuccess("BS saved");

          // Stop spinner and button text
          this.savingEmail = false;
          this.saveEmailButtonText = "Saved!";
          this.saveEmailButtonIcon = "check";

          // Change button text back to just save after 3 sec
          setTimeout(() => {
            this.saveEmailButtonText = "Save";
            this.saveEmailButtonIcon = "";
          }, 3000);
        })
        .catch((error) => {
          this.toastError(error);
          this.savingEmail = false;
        });
    },

    /**
     * Close modal
     */
    closeModal() {
      this.$emit("closeModal");
    },

    /**
     * Close variables modal
     */
    closeVariablesModal() {
      this.showVariables = false;
    },
  },
};
</script>
