<template>
    <of-row
        cols="4"
        hover
        @showBody="showBody"
    >
        <slot>
            <div>{{ new Date(contact.createdTs).toLocaleString() }}</div>
            <div>{{ contact.name }}</div>
            <div>{{ this.formatPhoneNo(contact.msisdn, contact.countryCode) }}</div>
            <div>{{ contact.email }}</div>
        </slot>
        <template v-slot:body>
            <div class="m-4 ml-3 mt-0 grid grid-cols-4 shadow rounded bg-white">
                <div class="p-4 border-r">
                    <app-contact-info :contact="contact" />
                </div>
                <div class="col-span-3 transition-all" :class="Padding">
                    <div class="mb-12">
                        <app-subscription-list :subscriptions="subscriptions" />
                    </div>
                    <app-payment-list :payments="payments" />
                </div>
            </div>
        </template>
    </of-row>
</template>

<script>
import axios from "axios";
import AppSubscriptionList from './../subscriptions/AppSubscriptionList.vue';
import AppPaymentList from './../payments/AppPaymentList.vue';
import AppContactInfo from './AppContactInfo.vue';
import { mapState } from 'vuex';
import visuals from "./../../../libs/visuals";

export default {
    name: "AppContactRow",
    components: {
        AppSubscriptionList,
        AppPaymentList,
        AppContactInfo,
    },
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            hasShownBody: false,
            subscriptions: null,
            payments: null,
        };
    },
    computed: {
        ...mapState(["visual"]),
        Padding() {
            return visuals[this.visual].container.padding;
        },
        RenderAddress() {
            let retHtml = '';

            if (this.contact.address) {
                retHtml += `<p>${this.contact.address}</p>`;
            }

            if (this.contact.postCode || this.contact.city || this.contact.countryCode) {
                retHtml += '<p>';

                if (this.contact.postCode) {
                    retHtml += this.contact.postCode;

                    if (this.contact.city) {
                        retHtml += ` ${this.contact.city}`;

                        if (this.contact.countryCode) {
                            retHtml += ` ${this.contact.countryCode}`;
                        }
                    } else if (this.contact.countryCode) {
                        retHtml += `-${this.contact.countryCode}`;
                    }
                }

                retHtml += '</p>';
            }

            return retHtml;
        }
    },
    methods: {
        formatPhoneNo(msisdn, countryCode) {
            if (countryCode == 'DK') {
                if (msisdn.length == 10) {
                return `+45 ${msisdn.substring(2,6)} ${msisdn.substring(6,10)}`;
                }
            }

            return msisdn;
        },
        showBody() {
            if (this.hasShownBody) {
                return false;
            }

            this.hasShownBody = true;

            axios.get(`${this.$store.state.OFconfig.api_payment_engine_url}/contact/${this.contact.contactGuid}/subscriptions`, {
                    headers: {
                    Authorization: this.$store.state.OFconfig.api_payment_engine_token,
                    }
                })
                .then((response) => {
                    this.subscriptions = response.data;
                })
                .catch(error => {
                    this.toastError(error);
                    this.sendingEmail = false;
                });

            axios.get(`${this.$store.state.OFconfig.api_payment_engine_url}/contact/${this.contact.contactGuid}/payments`, {
                    headers: {
                    Authorization: this.$store.state.OFconfig.api_payment_engine_token,
                    }
                })
                .then((response) => {
                    this.payments = response.data;
                })
                .catch(error => {
                    this.toastError(error);
                    this.sendingEmail = false;
                });
        }
    }
}
</script>