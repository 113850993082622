import axios from 'axios';
import md5 from 'js-md5';

// ACTIONS
const actions = {

    /**
     * Create communication
     */
    async create({ commit }, communication) {
        return new Promise((resolve, reject) => {
            axios.post(
                    `${this.state.apiUrl}/communicationCollection`,
                    communication, {
                        headers: {
                            Authorization: this.state.apiToken,
                            requestMD5: md5(JSON.stringify(communication)),
                        },
                    },
                )
                .then((response) => {
                    // Commit to mutation
                    commit('CREATE_COMMUNICATION', response.data);
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    },

    /**
     * Get communications
     */
    async get({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get(
                    `${this.state.apiUrl}/communicationCollections`, { headers: { Authorization: this.state.apiToken } },
                )
                .then((response) => {
                    // Commit to mutation
                    commit('SET_COMMUNICATIONS', response.data);
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },

    /**
     * Update communications
     */
    async update({ commit }, communication) {
        return new Promise((resolve, reject) => {
            const json = JSON.stringify({
                collectionName: communication.collectionName,
                parentGuid: communication.parentGuid,
            });

            axios.put(
                    `${this.state.apiUrl}/communicationCollection/${communication.communicationCollectionGuid}`, {
                        collectionName: communication.collectionName,
                        parentGuid: communication.parentGuid,
                    }, {
                        headers: {
                            Authorization: this.state.apiToken,
                            requestMD5: md5(json),
                        },
                    },
                )
                .then(() => {
                    commit('UPDATE_COMMUNICATION', communication);
                    resolve();
                })
                .catch((error) => reject(error));
        });
    },

    /**
     * Delete communications
     */
    async delete({ commit }, communication) {
        return new Promise((resolve, reject) => {
            const json = JSON.stringify(communication);

            axios.delete(
                    `${this.state.apiUrl}/communicationCollection/${communication.communicationCollectionGuid}`, {
                        headers: {
                            Authorization: this.state.apiToken,
                            requestMD5: md5(json),
                        },
                    },
                )
                .then(() => {
                    commit('REMOVE_COMMUNICATION', communication);
                    resolve();
                })
                .catch((error) => reject(error));
        });
    },

    /**
     * Set the selected communication
     */
    async setSelected({ commit }, communicationCollectionGuid) {
        // Set the communication as selected
        commit('SET_SELECTED', communicationCollectionGuid);

        // Get templates if there is a collection guid
        if (communicationCollectionGuid) {
            this.dispatch(
                'communications/templates/get',
                communicationCollectionGuid,
            );
        }
    },
};

export default actions;