<template>
  <li class="text-sm uppercase font-medium opacity-50 pt-10 pb-4 pl-6">
    <span v-if="show"><slot></slot></span>
    <span v-else style="margin-left:4px;"></span>
  </li>
</template>

<script>
export default {
  name: "OfMenuHeader",
  props: {
    show: Boolean,
  },
};
</script>
