import { createStore } from 'vuex';
import communications from './modules/communications';

/* eslint-disable */
const config_production = true;
const config_client_path = (typeof communication_config === 'object' ? communication_config.client_path : '');
const api_communication_url = (typeof communication_config === 'object' ? communication_config.api_url : (config_production ? 'https://sandbox.communication.api.onlinefundraising.dk' : 'https://sandbox.communication.api.onlinefundraising.dk'));
const api_communication_token = (typeof communication_config === 'object' ? communication_config.api_token : 'b3258858b3cd86452a13a08472d77a24c1342a8feaa46121c331d034fc741cd2'); // 
const stripoPluginId = '5cfb262ed6bc4e4ab5318d67180c0e3b';
const stripoSecretKey = '524287c15b274fbe835df595ae30be1e';
const stripoDefaultVersion = '1.40.0';
const visual = (localStorage.getItem('visual')) ? localStorage.getItem('visual') : 'default';

const OFconfig = {
    //Contacts, agreements, subscriptions (--> addons), payments, payment method
    "api_payment_engine_url":"https://sandbox.payment.api.onlinefundraising.dk",
    "api_payment_engine_token":"1bbafff9357ffa255fd95bc868133864f70332d766292843cc09067eb76bcd16",
    //Dataset
    "api_data_set_url":"https://sandbox.dataset.api.onlinefundraising.dk",
    "api_data_set_token":"5664792646e1f55f955caa7d3729bf7993b17f5a4e18539b8bbb4afb59773287",
    //Communications
    "api_communication_url":"https://sandbox.communication.api.onlinefundraising.dk",
    "api_communication_token":"b3258858b3cd86452a13a08472d77a24c1342a8feaa46121c331d034fc741cd2",
    "pageSize":25,
    "orgName":"Sandbox Merchant",
    "orgEmail":null,
    "has_bs_3rd":false,
    "has_ean_3rd":true,
    "has_giro_3rd":true,
    "has_inboice_3rd":true
};

/* eslint-enable */

export default createStore({
    state: {
        backendPath: config_client_path,
        apiUrl: api_communication_url,
        apiToken: api_communication_token,
        stripoPluginId,
        stripoSecretKey,
        stripoDefaultVersion,
        OFconfig,
        visual,
    },
    mutations: {
        SET_VISUAL(state, visual) {
            state.visual = visual;
        }
    },
    getters: {
        visual: (state) => state.visual,
    },
    actions: {
        setVisual({ commit }, visual) {
            localStorage.setItem('visual', visual);
            commit('SET_VISUAL', visual);
        },
    },
    modules: {
        communications,
    },
});