<template>
    <of-row
        cols="6"
        hover
        :background="BackgroundColor"
        @showBody="showBody"
    >
        <slot>
            <div>{{ payment.state }}</div>
            <div><template v-if="payment.createdTs">{{ new Date(payment.createdTs).toLocaleString() }}</template></div>
            <div>{{ payment.purposeAccountingCode }}</div>
            <!-- <div>{{ payment.paymentGatewayProvider }}</div> -->
            <div>{{ payment.paymentGatewayTransactionId }}</div>
            <div class="text-right">{{ Amount }}</div>
            <div>{{ payment.paymentType }}</div>
        </slot>
        <template v-slot:body>
            <div
                class="m-4 ml-3 mt-0  shadow rounded bg-white"
                :class="{ 'grid grid-cols-4': loadContact }"
            >
                <div v-if="loadContact" class="p-4 border-r">
                    <app-contact-info v-if="contact" :contact="contact" />
                </div>
                <div
                    class="transition-all"
                    :class="[ColSpan, Padding]"
                >
                    <of-row noOverflow hasButton>
                        <div class="col-span-3 font-bold">{{ this.$t(`Global.State`) }}</div>
                        <div class="col-span-7">{{ this.payment.state }}</div>
                        <div class="col-span-2 text-right">
                            <of-button v-if="this.payment.state == 'Charged'" danger sm rowButton>Refund</of-button>
                        </div>
                    </of-row>
                    <of-row v-if="this.payment.chargedTs">
                        <div class="col-span-3 font-bold">{{ this.$t(`Global.Charged`) }}</div>
                        <div class="col-span-9">{{ new Date(payment.chargedTs).toLocaleString() }}</div>
                    </of-row>
                    <of-row>
                        <div class="col-span-3 font-bold">{{ this.$t(`Global.Amount`) }}</div>
                        <div class="col-span-9">{{ Amount }}</div>
                    </of-row>
                    <of-row borderBottom>
                        <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.PurposeAccountCode`) }}</div>
                        <div class="col-span-9">{{ payment.purposeAccountingCode }}</div>
                    </of-row>
                    <div class="h-12"></div>
                    <of-row>
                        <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.PaymentType`) }}</div>
                        <div class="col-span-9">{{ payment.paymentType }}</div>
                    </of-row>
                    <of-row>
                        <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.CardType`) }}</div>
                        <div class="col-span-9">{{ payment.paymentMethodAccountingCode }}</div>
                    </of-row>
                    <template v-if="payment && payment.metaData">
                        <of-row v-if="payment.metaData.card && payment.metaData.card.cardNumber">
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.CardNumber`) }}</div>
                            <div class="col-span-9">{{ payment.metaData.card.cardNumber }}</div>
                        </of-row>
                    </template>
                    <of-row borderBottom>
                        <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.TransactionNumber`) }}</div>
                        <div class="col-span-9">{{ payment.paymentGatewayTransactionId }}</div>
                    </of-row>
                    <div class="h-12"></div>
                    <of-row>
                        <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.Gateway`) }}</div>
                        <div class="col-span-9">{{ payment.paymentGatewayProvider }}</div>
                    </of-row>
                    <of-row borderBottom>
                        <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.PaymentGatewayTransactionId`) }}</div>
                        <div class="col-span-9">{{ payment.paymentGatewayTransactionId }}</div>
                    </of-row>
                </div>
            </div>
        </template>
    </of-row>
</template>

<script>
import axios from "axios";
import AppContactInfo from "../contacts/AppContactInfo.vue";
import { mapState } from 'vuex';
import visuals from "./../../../libs/visuals";

export default {
    name: "AppPaymentRow",
    components: {
        AppContactInfo,
    },
    data() {
        return {
            contact: null,
            bodyShown: false,
        };
    },
    props: {
        payment: {
            type: Object,
            required: true,
        },
        loadContact: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(["visual"]),
        ColSpan() {
            if (this.loadContact) {
                return 'col-span-3'
            }
            return '';
        },
        Padding() {
            return visuals[this.visual].container.padding;
        },
        Amount() {
            const formatter = new Intl.NumberFormat("da", {
                style: "currency",
                currency: this.payment.currencyCode,
            });

            if (this.payment.amountPaid) {
                return formatter.format(this.payment.amountPaid);
            }

            return formatter.format(this.payment.amount);
        },
        BackgroundColor() {
            switch (this.payment.state) {
                case 'Refunded':
                case 'Cancelled':
                    return 'purple';
                case 'Pending':
                    return 'blue';
                case 'Rejected':
                case 'Failed':
                    return 'red';
                case 'Charged':
                    return 'green';
            }

            return '';
        },
    },
    methods: {
        showBody() {
            if (this.loadContact && !this.bodyShown) {
                this.bodyShown = true;
                    
                axios.get(`${this.$store.state.OFconfig.api_payment_engine_url}/contact/${this.payment.contactGuid}`, {
                    headers: {
                        Authorization: this.$store.state.OFconfig.api_payment_engine_token,
                    }
                    })
                    .then((response) => {
                        this.contact = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
    }
}
</script>