<template>
  <div
    class="absolute w-1/3 left-1/2 transform -translate-x-1/2 inset-y-8 bg-white z-999 shadow-xl rounded-lg p-6 overflow-y-auto"
  >
    <h2 class="text-xl">Variables</h2>
    <div @click="closeVariablesModal" class="fixed top-0 right-0 p-4 pointer">
      <fas icon="times" />
    </div>
    <p class="mt-2 mb-4">
      You may use al form field references, such as the following list, as
      variables in your Communication by wrapping them like so:
      {{ variableText }}
    </p>
    <ul>
      <li>date</li>
      <li>amount</li>
      <li>paymentMethodType - such as Card</li>
      <li>paymentMethodTypeTranslated</li>
      <li>paymentType - such as Payment</li>
      <li>paymentTypeTranslated</li>
      <li>recurringStartDate - yyyy-mm-dd</li>
      <li>recurringFrequency - such as monthly</li>
      <li>recurringFrequencyTranslated</li>
      <li>purposeName - name of chosen purpose</li>
      <li>agreementName</li>
      <li>name</li>
      <li>firstName</li>
      <li>lastName</li>
      <li>email</li>
      <li>phone</li>
      <li>address</li>
      <li>postCode</li>
      <li>city</li>
      <li>countryCode</li>
      <li>message</li>
      <li>etc.</li>
    </ul>
    <p class="mt-8 mb-4">
      You may also use the following variables, however only in emails and
      text-messages:
    </p>
    <ul>
      <li>successReturnPageUrl - the receipt URL</li>
      <li>paymentGatewayTransactionId</li>
      <li>paymentGatewaySubscriptionToken</li>
    </ul>
  </div>
  <div
    @click="closeVariablesModal"
    class="h-full w-full absolute top-0 left-0 bg-white pointer opacity-80 z-998"
  ></div>
</template>

<script>
export default {
  name: "AppVariablesModal",
  computed: {
    variableText() {
      return "{{variable}}";
    },
  },
  emits: ["closeVariablesModal"],
  methods: {
    closeVariablesModal() {
      this.$emit("closeVariablesModal");
    },
  },
};
</script>

<style scoped>
ul {
  padding-left: 20px;
}

ul li {
  list-style-type: disc;
  margin-top: 0.15rem;
}
</style>
