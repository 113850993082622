<template>
    <label>
      <input
        type="checkbox"
        :disabled="this.disabled"
        v-model="this.input"
        :checked="this.checked"
        value="true"
        @input="$emit('update:modelValue', $event.target.checked)"
      />
      <span class="check"></span>
      <slot></slot>
    </label>
</template>

<script>
import { ref } from "vue";

export default {
  name: "OfInputCheckbox",
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const input = ref();
    return { input };
  },
};
</script>
