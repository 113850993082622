<template>
  <div
    v-if="hasHeaderTitle || hasHeaderRight"
    class="view-header-wrap uik-entity-list-header"
  >
    <h2 v-if="hasHeaderTitle" class="header font-bold text-lg">
      <slot name="wrapHeaderTitle"></slot>
    </h2>
    <div v-if="hasHeaderRight" class="right-aligned popover-wrapper">
      <slot name="wrapHeaderRight"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfWrap",
  computed: {
    hasHeaderTitle() {
      return !!this.$slots.wrapHeaderTitle;
    },
    hasHeaderRight() {
      return !!this.$slots.wrapHeaderRight;
    },
  },
};
</script>
