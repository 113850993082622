<template>
  <of-modal
    noContentPadding
    @closeModal="closeModal"
    :showCloseModalButton="this.showModalCloseButton"
  >
    <!-- Modal header title -->
    <template v-slot:modalHeaderTitle>
      <span class="opacity-50">
        {{ this.communication.collectionName }} / {{ this.eventTypeName }}
      </span>
      / Email
    </template>

    <!-- Modal header right -->
    <template v-slot:modalHeaderRight>
      <span
        v-show="this.selectTemplates"
        class="mr-5 flex-wrap content-center"
        :class="{ flex: this.selectTemplates }"
      >
        <of-button
          sm
          transparent
          :icon="'times'"
          @click="this.selectTemplates = !this.selectTemplates"
        >
          Close templates
        </of-button>
      </span>
      <span
        v-show="this.previewEmail"
        class="mr-5 flex-wrap content-center"
        :class="{ flex: this.previewEmail }"
      >
        <of-button
          sm
          transparent
          :icon="'times'"
          @click="this.previewEmail = !this.previewEmail"
        >
          Close preview
        </of-button>
      </span>
      <div
        v-show="!this.selectTemplates && !this.previewEmail"
        :class="{
          'flex flex-wrap content-center':
            !this.selectTemplates && !this.previewEmail,
        }"
      >
        <of-button
          sm
          transparent
          :secondary="this.showCode"
          @click="this.showCode = !this.showCode"
          :icon="'code'"
          id="codeEditor"
          class="mr-5"
          :class="{ 'bg-blue-100': this.showCode }"
        >
          Code
        </of-button>
        <of-button
          sm
          :transparent="!this.selectTemplates"
          :secondary="this.selectTemplates"
          :icon="'th-large'"
          class="mr-5"
          @click="this.selectTemplates = !this.selectTemplates"
        >
          Templates
        </of-button>
        <of-button
          id="previewEmail"
          sm
          :transparent="!this.previewEmail"
          :secondary="this.previewEmail"
          @click="clickPreviewEmail"
          :icon="'mobile-alt'"
          class="mr-5"
        >
          Preview & Test
        </of-button>
        <of-button
          :loading="this.savingEmail"
          :icon="this.saveEmailButtonIcon"
          @click="this.saveEmail"
          sm
          class="ml-auto mr-0"
        >
          {{ this.saveButtonText }}
        </of-button>
      </div>
    </template>

    <!-- Modal content -->
    <template v-slot:modalContent>
      <div class="preview-container-fluid relative h-full">
        <div
          class="h-full grid grid-cols-12"
          style="position: relative"
          :class="{ hidden: previewEmail || selectTemplates }"
        >
          <div
            class="col-span-3 h-full border-r border-solid border-black border-opacity-10"
          >
            <div id="stripoSettingsContainer" class="h-full"></div>
          </div>
          <div class="col-span-9 h-full flex flex-col">
            <div
              class="pt-2 pb-2 bg-white border-b border-solid border-black border-opacity-10"
            >
              <div style="max-width: 600px; margin: auto">
                <div class="flex">
                  <span
                    class="p-1 pl-0 opacity-60 focus-next-input"
                    style="width: 100px"
                    >Sender email:</span
                  >
                  <of-input-text
                    class="flex-grow"
                    simple
                    widthAuto
                    v-model="emailSenderEmail"
                  />
                </div>
                <div
                  class="flex border-t border-b border-solid border-black border-opacity-10"
                >
                  <span
                    class="p-1 pl-0 opacity-60 focus-next-input"
                    style="width: 100px"
                    >Sender name:</span
                  >
                  <of-input-text
                    class="flex-grow"
                    simple
                    widthAuto
                    v-model="this.emailSenderName"
                  />
                </div>
                <div class="flex">
                  <span
                    class="p-1 pl-0 opacity-60 focus-next-input"
                    style="width: 100px"
                    >Subject:</span
                  >
                  <of-input-text
                    class="flex-grow"
                    simple
                    widthAuto
                    v-model="this.emailSubject"
                  />
                </div>
              </div>
            </div>
            <div id="stripoPreviewContainer" class="flex-grow"></div>
          </div>
        </div>
        <!-- Sender/Name/Subject -->
        <div
          class="absolute absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl p-6 z-999"
          v-if="this.showSenderNameSubject"
        >
          Test
        </div>
        <div
          class="absolute top-0 left-0 w-full h-full bg-white opacity-80 z-998"
          v-if="this.showSenderNameSubject"
        ></div>

        <!-- Preview and Test email -->
        <div
          class="grid grid-cols-12 h-full"
          :class="{ hidden: !previewEmail }"
        >
          <div class="col-span-8 p-5">
            <div
              class="h-full rounded-2xl bg-white flex flex-col overflow-hidden border-2 border-solid border-gray-200"
            >
              <div class="border-b-2 border-solid border-gray-200">
                <div
                  class="rounded-full border-2 border-solid m-2 inline-block border-gray-200 overflow-hidden"
                >
                  <fas
                    icon="user"
                    class="w-8 h-8 text-gray-200 rounded mt-1 -mb-2"
                  />
                </div>
              </div>
              <div class="flex-grow relative">
                <of-loading-spinner-absolute v-if="this.loadingEmailPreview" />
                <iframe
                  id="iframeDesktop"
                  frameborder="0"
                  style="height: 100%"
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="col-span-4 pt-4">
            <div
              class="rounded-3xl bg-white flex flex-col overflow-hidden m-auto border-2 border-solid border-gray-200"
              style="max-width: 320px"
            >
              <div class="grid p-6 justify-items-center">
                <div
                  class="h-2 w-16 bg-gray-200 align-middle rounded-full"
                ></div>
              </div>
              <div
                class="ml-2 mr-2 rounded border border-solid border-gray-200 overflow-hidden relative"
              >
                <of-loading-spinner-absolute v-if="this.loadingEmailPreview" />
                <iframe
                  id="iframeMobile"
                  frameborder="0"
                  width="100%"
                  height="500"
                ></iframe>
              </div>
              <div class="grid p-3 justify-items-center">
                <div
                  class="h-14 w-14 border-2 border-solid border-gray-200 align-middle rounded-full"
                ></div>
              </div>
            </div>
            <div class="p-6 pr-10">
              <p class="opacity-50 text-xs text-center">
                Please note that these previews may not reflect how the email
                will look like. We strongly suggest that you send a test email.
              </p>
            </div>
            <div
              class="p-4 m-2 mr-10 bg-white rounded-md border border-solid border-black border-opacity-5"
            >
              <p class="mb-1">Send test to</p>
              <of-input-text
                :placeholder="'Email'"
                v-model="testEmailEmailAddress"
              />
              <of-button
                :loading="this.sendingEmail"
                :icon="this.sendEmailButtonIcon"
                @click="this.clickSendTestEmail"
                sm
                class="w-full mt-2"
              >
                {{ this.sendTestEmailButtonText }}
              </of-button>
            </div>
          </div>
        </div>
        <app-stripo-templates
          v-if="this.selectTemplates"
          @selectTemplate="selectTemplate"
        />
      </div>
      <of-loading-spinner-absolute v-if="this.loadingStripo" />
    </template>
  </of-modal>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { defineAsyncComponent } from "vue";
import { mapActions } from "vuex";
import axios from "axios";
import $ from "jquery";
import md5 from "js-md5";
import mergeTags from "../../../../../helpers/mergeTags";
/* eslint-disable */
import giverPlaceholder from "../../../../../helpers/giverPlaceholder";
import toast from "../../../../../helpers/toast";
import eventTypes from "../../../../../helpers/communications/eventTypes";

const AppStripoTemplates = defineAsyncComponent(() => import("./AppStripoTemplates.vue"));

export default {
  name: "AppEventTemplateStripoEditor",
  components: {
    AppStripoTemplates
  },
  props: {
    communication: {
      type: Object,
      required: true
    },
    template: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showSenderNameSubject: false,
      showCode: false,
      selectTemplates: false,
      previewEmail: false,
      loadingStripo: true,
      loadingEmailPreview: true,
      savingEmail: false,
      saveEmailButtonText: "Save",
      saveEmailButtonIcon: "",
      sendingEmail: false,
      sendEmailButtonText: "Send",
      sendEmailButtonIcon: "paper-plane",
      emailHtml: null,
      emailSenderEmail: this.template.emailSenderEmail,
      emailSenderName: this.template.emailSenderName,
      emailSubject: this.template.emailSubject,
      testEmailEmailAddress: "",
      compiledEmail: null
    };
  },
  setup() {
    // Setup toast notification from '/src/helpers/toast.js'
    const { toastError, toastSuccess } = toast();
    const { giverPlaceholderReplacer } = giverPlaceholder();
    return { toastError, toastSuccess, giverPlaceholderReplacer };
  },
  computed: {
    /**
     * Save button text
     */
    saveButtonText() {
      if (this.savingEmail) {
        return "Saving";
      }

      return this.saveEmailButtonText;
    },

    /**
     * Save button text
     */
    sendTestEmailButtonText() {
      if (this.sendingEmail) {
        return "Sending";
      }

      return this.sendEmailButtonText;
    },

    /**
     * Show close button
     */
    showModalCloseButton() {
      return !this.selectTemplates && !this.previewEmail;
    },

    /**
     * Get stripo version
     */
    stripoVersion() {
      if (this.template.editorType) {
        return this.template.editorType.replace("stripo_", "");
      }

      return this.$store.state.stripoDefaultVersion;
    },

    /**
     * Get stripo version for when saving
     */
    stripoSaveVersion() {
      if (this.template.editorType) {
        return this.template.editorType;
      }

      return `stripo_${this.$store.state.stripoDefaultVersion}`;
    },

    /**
     * Get event type name
     */
    eventTypeName() {
      return eventTypes.find(x => x.eventType === this.template.eventType).name;
    }
  },
  emits: ["closeModal"],
  methods: {
    // Map actions (./store/modules/communications/templates/actions.js)
    ...mapActions({ updateTemplate: "communications/templates/update" }),

    /**
     * Preview email
     */
    clickPreviewEmail() {
      this.loadingEmailPreview = true;
      this.previewEmail = !this.previewEmail;
    },

    /**
     * Send test email
     */
    clickSendTestEmail() {
      if (!this.validateEmail()) {
        this.toastError(null, "Email address not valid");
        return false;
      }

      // Start spinner and button text
      this.sendingEmail = true;

      const body = {
        email: this.testEmailEmailAddress,
        emailHtml: this.compiledEmail,
        emailSubject: this.emailSubject
      };

      axios
        .post(`${this.$store.state.apiUrl}/communicationTemplate/TestEmail`, body, {
          headers: {
            Authorization: this.$store.state.apiToken,
            requestMD5: md5(JSON.stringify(body))
          }
        })
        .then(() => {
          // Notify user of success
          this.toastSuccess("Email sent");

          // Stop spinner and button text
          this.sendingEmail = false;
          this.sendEmailButtonText = "Test email sent";
          this.sendEmailButtonIcon = "check";

          // Change button text back to just save after 3 sec
          setTimeout(() => {
            this.sendEmailButtonText = "Send";
            this.sendEmailButtonIcon = "paper-plane";
          }, 3000);
        })
        .catch(error => {
          this.toastError(error);
          this.sendingEmail = false;
        });

      return true;
    },

    /**
     * Select template
     */
    selectTemplate(stripoTemplate) {
      this.loadingStripo = true;
      this.selectTemplates = false;
      window.StripoApi.stop();
      this.initStripo(stripoTemplate.html, stripoTemplate.css);
    },

    /**
     * Save email
     */
    saveEmail() {
      // Start spinner and button text
      this.savingEmail = true;

      // Generate html and css from Stripo
      window.StripoApi.getTemplate((html, css) => {
        // The values to update
        const updateInTemplate = {
          communicationCollectionGuid: this.template.communicationCollectionGuid,
          communicationTemplateGuid: this.template.communicationTemplateGuid,
          stripoHtml: html,
          stripoCss: css,
          emailSenderEmail: this.emailSenderEmail,
          emailSenderName: this.emailSenderName,
          emailSubject: this.emailSubject,
          editorType: this.stripoSaveVersion
        };

        // Compile email from Stripo
        window.StripoApi.compileEmail((error, compiledHtml) => {
          // No errors while compiling, let's go!
          if (!error) {
            // Set emailHtml to compiled html
            updateInTemplate.emailHtml = compiledHtml;

            // Call update template action
            this.updateTemplate(updateInTemplate)
              .then(() => {
                // Notify user of success
                this.toastSuccess("Email saved");

                // Stop spinner and button text
                this.savingEmail = false;
                this.saveEmailButtonText = "Saved!";
                this.saveEmailButtonIcon = "check";

                // Change button text back to just save after 3 sec
                setTimeout(() => {
                  this.saveEmailButtonText = "Save";
                  this.saveEmailButtonIcon = "";
                }, 3000);
              })
              .catch(updateTemplateError => {
                this.toastError(updateTemplateError);
                this.savingEmail = false;
              });
          }
        });
      });
    },

    /**
     * Stripo
     */
    initStripo(html, css) {
      const _this = this;

      const apiRequestData = {
        emailId: this.communication.merchantId // C6W1b$N29jlH&ocL || this.communication.merchantId
      };

      const script = document.createElement("script");
      script.id = "stripoScript";
      script.type = "text/javascript";
      script.src = `https://plugins.stripo.email/static/rev/${_this.stripoVersion}/stripo.js`;

      script.onload = function() {
        window.Stripo.init({
          settingsId: "stripoSettingsContainer",
          previewId: "stripoPreviewContainer",
          codeEditorButtonId: "codeEditor",
          locale: "en",
          html,
          css,
          apiRequestData,
          userFullName: "Online Fundraising",
          onInitialized: () => {
            _this.loadingStripo = false;
          },
          getAuthToken: callback => {
            axios
              .post(
                "https://plugins.stripo.email/api/v1/auth",
                JSON.stringify({
                  pluginId: _this.$store.state.stripoPluginId,
                  secretKey: _this.$store.state.stripoSecretKey,
                  role: "ADMIN"
                }),
                {
                  headers: {
                    "Content-Type": "application/json"
                  }
                }
              )
              .then(response => {
                callback(response.data.token);
              })
              .catch(error => {
                this.toastError(error);
              });
          },
          mergeTags
        });
      };
      document.body.appendChild(script);
    },

    /**
     * Close modal
     */
    closeModal() {
      window.StripoApi.stop();
      this.$emit("closeModal");
    },

    validateEmail() {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.testEmailEmailAddress);
    }
  },
  mounted() {
    this.initStripo(this.template.stripoHtml, this.template.stripoCss);

    /* eslint-disable */
    const _this = this;

    $(document).on("click", "#previewEmail", function() {
      window.StripoApi.compileEmail(function(error, html) {
        html = _this.giverPlaceholderReplacer(html);
        var iframeDesktop = document.querySelector("#iframeDesktop");
        iframeDesktop.contentWindow.document.open("text/html", "replace");
        iframeDesktop.contentWindow.document.write(html);
        iframeDesktop.contentWindow.document.close();

        var iframeMobile = document.querySelector("#iframeMobile");
        iframeMobile.contentWindow.document.open("text/html", "replace");
        iframeMobile.contentWindow.document.write(html);
        iframeMobile.contentWindow.document.close();

        if (error) {
          console.log(error);
        } else {
          _this.loadingEmailPreview = false;
          _this.compiledEmail = html;
        }
      });
    });

    $(document).on("click", ".focus-next-input", function() {
      $(this)
        .next("input")
        .focus();
    });
    /* eslint-enable no-return-assign, no-param-reassign */
  }
};
</script>

<style>
body {
  background: #fff;
  margin: 0;
  padding: 0;
}

#externalSystemContainer {
  background-color: darkgrey;
  padding: 5px 0 5px 20px;
}
#undoButton,
#redoButton {
  display: none;
}

#stripoSettingsContainer .panel-collapse {
  background-color: #fff;
}

.control-button {
  border-radius: 17px;
  padding: 5px 10px;
  border-color: grey;
}
#changeHistoryLink {
  cursor: pointer;
}

.es-wrapper-color {
  padding-left: 440px;
}
</style>
