<template>
    <h2 class="mb-2">Subscriptions</h2>
    <div class="rounded overflow-hidden" :class="Shadow">
        <div v-if="!subscriptions">Loading</div>
        <template v-else>
            <app-row-header cols="4">
                <div>{{ this.$t(`Global.State`) }}</div>
                <div>{{ this.$t(`Global.Created`) }}</div>
                <div>{{ this.$t(`Global.Payment.NextDueDate`) }}</div>
                <div>{{ this.$t(`Global.Payment.PaymentType`) }}</div>
            </app-row-header>
            <app-subscription-row
                v-for="(subscription, index) in subscriptions"
                :key="index"
                :subscription="subscription"
                :loadContact="loadContact"
            />
        </template>
    </div>
</template>

<script>
import AppSubscriptionRow from './AppSubscriptionRow.vue';
import AppRowHeader from './../../shared/layout/OfRowHeader.vue';

export default {
    name: "AppSubscriptionList",
    components: {
        AppSubscriptionRow,
        AppRowHeader,
    },
    props: {
        subscriptions: {
            type: Object,
            required: true,
        },
        shadow: {
            type: String,
        },
        loadContact: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        Shadow() {
            if (this.shadow) {
                return `${this.shadow} bg-white`;
            }

            return 'border';
        },
    },
}
</script>