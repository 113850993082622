<template>
  <div class="login">
    <h1>This is the login page</h1>
  </div>
</template>

<script>
export default {
  name: "Login",
  created() {
    console.log(this.$store.state.loginClientId);
  },
};
</script>
