<template>
  <of-wrap :padding="'p-0'">
    <template v-slot:wrapContent>
      <div class="grid grid-cols-12">
        <!-- Left tree menu -->
        <div
          class="col-span-2 pr-4"
          v-if="communicationsTree"
        >
          <h2 class="text-xl font-bold">{{ $t("Communication.Header") }}</h2>
          <of-button
            sm
            transparent
            icon="plus"
            class="mt-4"
            @click.prevent="setCommunicationToEdit"
          >
            New
          </of-button>
          <div
            class="mt-6 -ml-4"
            v-for="communication in communicationsTree"
            :key="communication.communicationCollectionGuid"
          >
            <app-communications-menu-tree :communication="communication" />
          </div>
        </div>

        <!-- Main body -->
        <div class="col-span-10 pl-6">
          <of-card shadow="xl" v-if="selectedCommunication">
            <!-- If there is a selected communication -->
            <app-communication
              @editCommunication="editCommunication"
              :communication="selectedCommunication"
            />
          </of-card>
        </div>
      </div>
    </template>
  </of-wrap>

  <!-- Edit modal -->
  <app-communnication-edit-modal
    @closeModal="closeModal"
    :communication="this.communicationToEdit"
    :communicationsTree="communicationsTree"
    v-if="this.communicationToEdit"
  />
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import AppCommunication from "./AppCommunication.vue";
import AppCommunicationsMenuTree from "./menu/AppCommunicationsMenuTree.vue";
import AppCommunnicationEditModal from "./AppCommunicationEditModal.vue";

const { mapState } = createNamespacedHelpers("communications");

export default {
  name: "Communications",
  components: {
    AppCommunication,
    AppCommunicationsMenuTree,
    AppCommunnicationEditModal,
  },
  data() {
    return {
      communicationToEdit: null,
    };
  },
  computed: {
    ...mapState(["communications"]),

    /**
     * Render the communications menu tree
     */
    communicationsTree() {
      const dataTree = [];

      if (this.communications) {
        const hashTable = Object.create(null);
        this.communications.forEach(
          // eslint-disable-next-line no-return-assign
          (aData) =>
            (hashTable[aData.communicationCollectionGuid] = {
              ...aData,
              communications: [],
            })
        );
        this.communications.forEach((aData) => {
          if (aData.parentGuid) {
            if (hashTable[aData.parentGuid]) {
              hashTable[aData.parentGuid].communications.push(
                hashTable[aData.communicationCollectionGuid]
              );
            }
          } else dataTree.push(hashTable[aData.communicationCollectionGuid]);
        });
      }

      return dataTree;
    },

    /**
     * Set selected communication
     */
    selectedCommunication() {
      if (this.$route.query.guid) {
        this.$store.dispatch(
          "communications/setSelected",
          this.$route.query.guid
        );
      } else {
        this.$store.dispatch("communications/setSelected", null);
      }

      if (this.communications) {
        return this.communications.find((x) => x.selected === true);
      }

      return null;
    },
  },
  mounted() {
    this.$store.dispatch("communications/get");
  },
  methods: {
    /**
     * This fakes an edit and instead creates a new communication in the modal
     */
    setCommunicationToEdit() {
      const communication = {
        communicationCollectionGuid: null,
        collectionName: null,
        parentGuid: null,
      };

      this.communicationToEdit = communication;
    },

    /**
     * Edit the currently selected communication and open the edit modal
     */
    editCommunication() {
      this.communicationToEdit = this.selectedCommunication;
    },

    /**
     * Close modal
     */
    closeModal() {
      this.communicationToEdit = null;
    },
  },
};
</script>
