<template>
  <of-modal noContentPadding @closeModal="closeModal">
    <!-- Modal header title -->
    <template v-slot:modalHeaderTitle>
      <span class="opacity-50">
        {{ this.communication.collectionName }} / {{ this.eventTypeName }}
      </span>
      / On screen html
    </template>

    <!-- Modal header right -->
    <template v-slot:modalHeaderRight>
      <of-button
        sm
        transparent
        class="mr-6"
        :icon="'code'"
        @click="this.showVariables = !this.showVariables"
      >
        Variables
      </of-button>
      <of-button
        :loading="this.savingEmail"
        :icon="this.saveEmailButtonIcon"
        id="saveScreenHtml"
        sm
        class="ml-auto mr-0"
      >
        {{ this.saveButtonText }}
      </of-button>
    </template>

    <!-- Modal content -->
    <template v-slot:modalContent>
      <div class="preview-container-fluid relative h-full">
        <div class="grid grid-cols-12 h-full">
          <div class="col-span-6 h-full pt-6 pl-6 pb-6">
            <div
              id="screenHtmlCodeMirror"
              class="overflow-hidden border h-full rounded-tl-md rounded-bl-md"
            >
              <textarea id="codemirror" v-model="this.screenHtml"></textarea>
            </div>
          </div>
          <div class="col-span-6 h-full pt-6 pr-6 pb-6">
            <div
              class="overflow-hidden border border-l-0 rounded-tr-md rounded-br-md h-full"
            >
              <iframe
                id="codemirrorpreview"
                style="padding: 15px; width: 100%; height: 100%"
              ></iframe>
            </div>
          </div>
        </div>
        <app-variables-modal
          v-if="this.showVariables"
          @closeVariablesModal="closeVariablesModal"
        >
        </app-variables-modal>
      </div>
    </template>
  </of-modal>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapActions } from "vuex";
import $ from "jquery";
import "codemirror/lib/codemirror.css";
import CodeMirror from "codemirror";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/htmlmixed/htmlmixed";
import AppVariablesModal from "../../AppVariablesModal.vue";
import toast from "../../../../../helpers/toast";
import eventTypes from "../../../../../helpers/communications/eventTypes";

export default {
  name: "AppEventTemplateScreenHtmlEditor",
  components: {
    AppVariablesModal,
  },
  props: {
    communication: {
      type: Object,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      savingEmail: false,
      saveEmailButtonText: "Save",
      saveEmailButtonIcon: "",
      screenHtml: this.template.screenHtml,
      showVariables: false,
    };
  },
  setup() {
    // Setup toast notification from '/src/helpers/toast.js'
    const { toastError, toastSuccess } = toast();
    return { toastError, toastSuccess };
  },
  computed: {
    saveButtonText() {
      if (this.savingEmail) {
        return "Saving";
      }

      return this.saveEmailButtonText;
    },

    eventTypeName() {
      return eventTypes.find((x) => x.eventType === this.template.eventType)
        .name;
    },
  },
  emits: ["closeModal"],
  methods: {
    // Map actions (./store/modules/communications/templates/actions.js)
    ...mapActions({ updateTemplate: "communications/templates/update" }),

    /**
     * Close modal
     */
    closeModal() {
      this.$emit("closeModal");
    },

    /**
     * Close variables modal
     */
    closeVariablesModal() {
      this.showVariables = false;
    },
  },
  mounted() {
    const _this = this;
    let delay;

    // Initialize CodeMirror
    const editor = CodeMirror.fromTextArea(
      document.getElementById("codemirror"),
      {
        mode: "text/html",
        lineNumbers: true,
        line: true,
        lineWrapping: true,
      }
    );

    // Update preview
    function updatePreview() {
      // Find the preview frame
      const previewFrame = document.getElementById("codemirrorpreview");

      // Then find the preview
      const preview =
        previewFrame.contentDocument || previewFrame.contentWindow.document;

      // Get value from editor and insert it into preview
      preview.open();
      preview.write(editor.getValue());
      preview.close();
    }

    // When CodeMirror changes
    editor.on("change", () => {
      clearTimeout(delay);
      delay = setTimeout(updatePreview, 300);
    });

    // Run updatePreview after 300 on init
    setTimeout(updatePreview, 300);

    // Click "Save" button
    $(document).on("click", "#saveScreenHtml", () => {
      // Start spinner and button text
      _this.savingEmail = true;

      // The values to update
      const updateInTemplate = {
        communicationCollectionGuid: _this.template.communicationCollectionGuid,
        communicationTemplateGuid: _this.template.communicationTemplateGuid,
      };

      // Get the value from editor
      updateInTemplate.screenHtml = editor.getValue();

      // Call update template action
      _this
        .updateTemplate(updateInTemplate)
        .then(() => {
          // Notify user of success
          this.toastSuccess("Screen Html saved");

          // Stop spinner and button text
          _this.savingEmail = false;
          _this.saveEmailButtonText = "Saved!";
          _this.saveEmailButtonIcon = "check";

          // Change button text back to just save after 3 sec
          setTimeout(() => {
            _this.saveEmailButtonText = "Save";
            _this.saveEmailButtonIcon = "";
          }, 3000);
        })
        .catch((error) => {
          this.toastError(error);
          this.loadingCreate = false;
        });
    });
  },
};
</script>

<style>
#screenHtmlCodeMirror .CodeMirror {
  height: 100% !important;
}
</style>
