<template>
  <button
    class="border bg-orange-500 rounded-sm font-semibold shadow-xs hover:shadow-sm transition"
    :disabled="disabled || loading"
    :style="{
      background: Background,
      color: Color,
      border: Border,
      margin: Margin,
    }"
    :class="{
      'border-opacity-70': !transparent && !danger && !secondary && !archive,
      'border-opacity-10': transparent || danger || secondary || archive,
      'py-2 px-5 text-sm': !xs && !sm,
      'py-1 px-3 text-xs': xs || sm,
      'bg-orange-500': secondary,
      'btn-clear': clear,
      'bg-orange-500': archive,
      'bg-red-500 text-white border-opacity-10': danger,
      'rounded-r-none border-r-0': leftGroup,
      'rounded-l-none': rightGroup,
    }"
  >
    <fas
      v-if="computedIcon"
      :class="{
        'animate-spin': loading || spin,
        '-ml-1 mr-2 text-xs': sm || xs,
      }"
      :icon="computedIcon"
    />
    <slot> </slot>
  </button>
</template>

<script>
export default {
  name: "OfButton",
  props: {
    xs: Boolean,
    sm: Boolean,
    secondary: Boolean,
    transparent: Boolean,
    clear: Boolean,
    archive: Boolean,
    danger: Boolean,
    spin: Boolean,
    loading: Boolean,
    disabled: Boolean,
    leftGroup: Boolean,
    rightGroup: Boolean,
    rowButton: Boolean,
    icon: {
      type: String,
      default: "",
      required: false,
    },
  },
  computed: {
    computedIcon() {
      if (this.loading) {
        return "circle-notch";
      }
      if (this.icon !== "") {
        return this.icon;
      }
      return null;
    },
    Primary() {
      return "";
    },
    Color() {
      if (!this.transparent && !this.danger && !this.secondary && !this.archive) {
        return "#fff";
      }
      return "";
    },
    Border() {
      if (!this.transparent && !this.danger && !this.secondary && !this.archive) {
        return "rgba(20,147,115,.5)";
      }

      return "";
    },
    Background() {
      if (!this.transparent && !this.danger && !this.secondary && !this.archive) {
        return "linear-gradient(45deg,#149373,#37b877)";
      }

      return "#fff";
    },
    Margin() {
      if (this.rowButton) {
        return '-3px 0';
      }
      return '';
    }
  },
};
</script>
