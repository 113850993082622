<template>
  <of-modal narrow @closeModal="closeModal">
    <!-- Modal header title -->
    <template v-slot:modalHeaderTitle>
      <template v-if="communication.communicationCollectionGuid">
        {{ this.communication.collectionName }}
      </template>
      <template v-else> Create new communication </template>
    </template>

    <!-- Modal header right -->
    <template v-slot:modalHeaderRight>
      <of-button
        sm
        danger
        icon="trash"
        class="ml-auto mr-0"
        :loading="this.loadingDelete"
        v-if="communication.communicationCollectionGuid"
        @click="deleteCommunication"
      >
        {{ deleteButtonText }}
      </of-button>
    </template>

    <!-- Modal content -->
    <template v-slot:modalContent>
      <!-- Name -->
      <div class="of-row grid grid-cols-12">
        <div class="col-span-12 md:col-span-6">
          <p class="font-bold mb-1">Name</p>
          <input
            class="uik-input__input"
            type="text"
            placeholder="Name"
            v-model="this.editCommunication.collectionName"
          />
        </div>
      </div>

      <!-- Select parent -->
      <div class="of-row grid grid-cols-12">
        <div class="col-span-12 md:col-span-6">
          <p class="font-bold mb-1 mt-4">Parent</p>
          <select
            class="uik-input__select"
            v-model="this.editCommunication.parentGuid"
          >
            <option :value="null">Choose parent communication</option>
            <option
              v-for="option in this.getSelectList"
              :key="option.guid"
              :value="option.guid"
              v-html="option.name"
            ></option>
          </select>
        </div>
      </div>

      <!-- Create/update button -->
      <div class="grid grid-cols-12 pt-6">
        <div class="col-span-12 md:col-span-6">
          <of-button
            v-if="this.communication.communicationCollectionGuid"
            :loading="this.loadingCreate"
            @click="updateCommunication"
          >
            {{ saveButtonText }}
          </of-button>
          <of-button
            v-else
            :loading="this.loadingCreate"
            @click.prevent="createCommunication"
          >
            {{ createButtonText }}
          </of-button>
        </div>
      </div>
    </template>
  </of-modal>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import toast from "../../../helpers/toast";

export default {
  name: "AppCommunnicationEditModal",
  props: {
    communication: {
      type: Object,
      required: true,
    },
    communicationsTree: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editCommunication: this.communication,
      loadingCreate: false,
      loadingDelete: false,
    };
  },
  computed: {
    saveButtonText() {
      if (this.loadingCreate) return "Saving";
      return "Save";
    },
    createButtonText() {
      if (this.loadingCreate) return "Creating";
      return "Create";
    },
    deleteButtonText() {
      if (this.loadingDelete) return "Deleting";
      return "Delete";
    },
    getSelectList() {
      const select = [];

      // Create the select options with simulated tree structure
      const createSelectOption = (communication, indent) => {
        // Create the option (with indent '&nbsp')
        const option = {
          guid: communication.communicationCollectionGuid,
          name: indent + communication.collectionName,
        };

        // Add to array
        select.push(option);

        // If any children, call the function again
        if (communication.communications) {
          for (let i = 0; i < communication.communications.length; i += 1) {
            createSelectOption(
              communication.communications[i],
              `${indent}&nbsp;&nbsp;&nbsp;&nbsp;`
            );
          }
        }
      };

      // Let's run through all the tree structure of communications
      for (let i = 0; i < this.communicationsTree.length; i += 1) {
        createSelectOption(this.communicationsTree[i], "");
      }

      // Woop, return the array
      return select;
    },
  },
  emits: ["closeModal"],
  setup() {
    const { toastError, toastSuccess } = toast();
    return { toastError, toastSuccess };
  },
  methods: {
    // Map actions (./store/modules/communications/actions.js)
    ...mapActions({
      actionCreateCommunication: "communications/create",
      actionUpdateCommunication: "communications/update",
      actionDeleteCommunication: "communications/delete",
    }),
    ...mapMutations({
      mutateSetSelectedCommunication: "communications/SET_SELECTED",
    }),

    /**
     * Create communication
     */
    async createCommunication() {
      // Start spinner and button text
      this.loadingCreate = true;

      if (!this.editCommunication.collectionName) {
        this.toastError(null, "Please provide a name.");
        this.loadingCreate = false;
        return;
      }

      if (!this.editCommunication.parentGuid) {
        this.toastError(null, "Please select a parent communication.");
        this.loadingCreate = false;
        return;
      }

      // Call create action
      await this.actionCreateCommunication(this.editCommunication)
        .then((result) => {
          // Stop spinner and button text
          this.loadingCreate = false;

          // Change url to newly created communication
          this.$router.push({
            query: {
              page: "communications",
              guid: result.communicationCollectionGuid,
            },
          });

          // Close modal
          this.$emit("closeModal");

          // Set the state of communication selected to true
          this.mutateSetSelectedCommunication(
            result.communicationCollectionGuid
          );

          // Notify user of success
          this.toastSuccess("Communication created");
        })
        .catch((error) => {
          this.toastError(error);
          this.loadingCreate = false;
        });
    },

    /**
     * Update communication
     */
    async updateCommunication() {
      // Start spinner and button text
      this.loadingCreate = true;

      if (!this.editCommunication.collectionName) {
        this.toastError(null, "Please provide a name.");
        this.loadingCreate = false;
        return;
      }

      if (!this.editCommunication.parentGuid) {
        this.toastError(null, "Please select a parent communication.");
        this.loadingCreate = false;
        return;
      }

      // Call update action
      await this.actionUpdateCommunication(this.editCommunication)
        .then(() => {
          // Stop spinner and button text
          this.loadingCreate = false;

          // Close modal
          this.$emit("closeModal");

          // Notify user of success
          this.toastSuccess("Communication updated");
        })
        .catch((error) => {
          this.toastError(error);
          this.loadingCreate = false;
        });
    },

    /**
     * Delete communication
     */
    async deleteCommunication() {
      // Start spinner and button text
      this.loadingDelete = true;

      // Confirm delete
      if (
        window.confirm(
          `Are you sure you want to delete ${this.editCommunication.collectionName}`
        )
      ) {
        // Call delete action
        await this.actionDeleteCommunication(this.editCommunication)
          .then(() => {
            // Notify user of success
            this.toastSuccess("Communication deleted");

            // Close modal
            this.$emit("closeModal");

            // Change url to just communications
            this.$router.push({
              query: { page: "communications" },
            });
          })
          .catch((error) => {
            this.toastError(error);
            this.loadingCreate = false;
          });
      } else {
        // Don't delete, stop spinner and button text
        this.loadingDelete = false;
      }
    },

    /**
     * Close modal
     */
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
