<template>
    <h2 class="mb-2">Contacts</h2>
    <div class="rounded overflow-hidden" :class="Shadow">
        <div v-if="!contacts">Loading</div>
        <template v-else>
            <app-row-header cols="4">
                <div>{{ this.$t(`Global.Created`) }}</div>
                <div>{{ this.$t(`Global.Name`) }}</div>
                <div>{{ this.$t(`Global.User.Phone`) }}</div>
                <div>{{ this.$t(`Global.User.Email`) }}</div>
            </app-row-header>
            <app-contact-row
                :contact="contact"
                v-for="(contact, index) in this.contacts"
                :key="index" />
        </template>
    </div>
</template>

<script>
import AppContactRow from './AppContactRow.vue';
import AppRowHeader from './../../shared/layout/OfRowHeader.vue';

export default {
    name: "AppContactList",
    components: {
        AppContactRow,
        AppRowHeader,
    },
    props: {
        contacts: {
            type: Object,
        },
        shadow: {
            type: String,
            default: '',
        },
    },
    computed: {
        Shadow() {
            if (this.shadow) {
                return `${this.shadow} bg-white`;
            }

            return 'border';
        },
    },
}
</script>