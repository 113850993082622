<template>
  <app-contact-list shadow="shadow" :contacts="contacts" />
</template>

<script>
import axios from "axios";
import AppContactList from './../components/app/contacts/AppContactList.vue';

export default {
  name: "Payments",
  components: {
    AppContactList,
  },
  data() {
      return {
          contacts: null,
      };
  },
  created() {
    this.getContacts();
  },
  methods: {
    getContacts() {
      axios.get(`${this.$store.state.OFconfig.api_payment_engine_url}/contacts?pageNumber=1&pageSize=20&archived=false`, {
          headers: {
            Authorization: this.$store.state.OFconfig.api_payment_engine_token,
          }
        })
        .then((response) => {
          this.contacts = response.data.list;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
};
</script>
