<template>
  <div
    class="p-4 bg-white"
    :class="[rounded ? Rounded : 'rounded', shadow ? Shadow : 'shadow']"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "OfCard",
  props: {
    rounded: String,
    shadow: String,
  },
  computed: {
    Rounded() {
      return `rounded-${this.rounded}`;
    },
    Shadow() {
      return `shadow-${this.shadow}`;
    },
  },
};
</script>
