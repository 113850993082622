// MUTATIONS
const mutations = {

    /**
     * Create communication
     */
    CREATE_COMMUNICATION(state, communication) {
        state.communications.push(communication);
    },

    /**
     * Update communication
     */
    UPDATE_COMMUNICATION(state, communication) {
        // Find the communication and update it
        const index = state.communications.findIndex(
            (x) => x.communicationCollectionGuid === communication.communicationCollectionGuid,
        );

        state.communications[index].collectionName = communication.collectionName;
        state.communications[index].parentGuid = communication.parentGuid;
    },

    /**
     * Remove communication
     */
    REMOVE_COMMUNICATION(state, communication) {
        const i = state.communications
            .map((x) => x.communicationCollectionGuid)
            .indexOf(communication.communicationCollectionGuid);
        state.communications.splice(i, 1);
    },

    /**
     * Set communication
     */
    SET_COMMUNICATIONS(state, communications) {
        state.communications = communications;
    },

    /**
     * Set selected communication
     */
    SET_SELECTED(state, communicationGuid) {
        // unselect the currently selected
        if (state.communications && state.communications.find((x) => x.selected === true)) {
            state.communications.find((x) => x.selected === true).selected = false;
        }

        // Find the communication to select
        if (state.communications && communicationGuid) {
            for (let i = 0; i < state.communications.length; i += 1) {
                state.communications[i].selected = false;
                if (state.communications[i].communicationCollectionGuid === communicationGuid) {
                    state.communications[i].selected = true;
                    break;
                }
            }
        }
    },

    /**
     * Set communication to edit
     */
    SET_EDITING(state, communicationGuid) {
        // unselect the currently selected
        if (state.communications.find((x) => x.edit === true)) {
            state.communications.find((x) => x.edit === true).edit = false;
        }

        // Find the communication to select
        for (let i = 0; i < state.communications.length; i += 1) {
            state.communications[i].edit = false;
            if (state.communications[i].communicationCollectionGuid === communicationGuid) {
                state.communications[i].edit = true;
                break;
            }
        }
    },

    /**
     * Add template to communication
     */
    ADD_COMMUNICATION_TEMPLATE(state, template) {
        // Find the communication and set templates
        state.communications.find(
            (x) => x.communicationCollectionGuid === template.communicationCollectionGuid,
        ).templates.push(template);
    },

    /**
     * Update template in communication
     */
    UPDATE_COMMUNICATION_TEMPLATE(state, { communicationCollectionGuid, template }) {
        for (let i = 0; i < state.communications.length; i += 1) {
            if (state.communications[i].communicationCollectionGuid ===
                communicationCollectionGuid) {
                for (let n = 0; n < state.communications[i].templates.length; n += 1) {
                    if (state.communications[i].templates[n].communicationTemplateGuid ===
                        template.communicationTemplateGuid) {
                        state.communications[i].templates[n] = template;
                    }
                }
            }
        }
    },

    /**
     * Set templates in communication
     */
    SET_COMMUNICATION_TEMPLATES(state, { communicationCollectionGuid, templates }) {
        // Find the communication and set templates
        state.communications.find(
            (x) => x.communicationCollectionGuid === communicationCollectionGuid,
        ).templates = templates;
    },
};

export default mutations;