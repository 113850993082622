<template>
  <li>
    <a
      :href="Url"
      @click="toggleShow"
      class="flex items-center mx-3 py-2.5 hover:pl-4 transition-all rounded bg-black bg-opacity-0"
      :class="{
        'bg-opacity-10': isShow,
      }"
    >
      <div class="w-10 mr-3 flex items-center justify-center">
        <fas
          style="font-size: 20px"
          class="opacity-90"
          v-if="computedIcon"
          :icon="computedIcon"
        />
      </div>
      <span class="text-md"><slot></slot></span>
      <div
        class="absolute right-5 opacity-60 transform transition-all"
        :class="{ 'rotate-90': isShow }"
      >
        <fas v-if="hasSub" icon="chevron-right" style="width: 8px" />
      </div>
    </a>
    <slide-up-down v-model="isShow" :duration="300" v-if="hasSub">
      <ul>
        <slot name="sub"></slot>
      </ul>
    </slide-up-down>
  </li>
</template>

<script>
import SlideUpDown from "vue3-slide-up-down";

export default {
  name: "OfMenuItem",
  components: {
    SlideUpDown,
  },
  props: {
    icon: {
      type: String,
      default: "",
      required: false,
    },
    url: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    computedIcon() {
      if (this.icon !== "") {
        return this.icon;
      }
      return null;
    },
    Url() {
      if (this.url === "") {
        return '#';
      }
      return this.url;
    },
    hasSub() {
      return !!this.$slots.sub;
    },
  },
  methods: {
    toggleShow() {
      if (this.hasSub) {
        this.isShow = !this.isShow;
      }
    },
  },
};
</script>
