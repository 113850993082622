<template>
  <div class="absolute inset-0 h-full w-full z-999">
    <div class="text-center grid h-full">
      <fas
        icon="circle-notch"
        class="w-14 h-14 opacity-20 animate-spin m-auto"
      />
    </div>
  </div>
  <div class="absolute inset-0 bg-white opacity-70 z-998"></div>
</template>

<script>
export default {
  name: "OfLoadingSpinnerAbsolute",
};
</script>
