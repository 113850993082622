const mergeTags = [
  {
    category: "Online Fundraising",
    entries: [
      {
        label: "Date",
        value: "{{date}}",
      },
      {
        label: "Amount",
        value: "{{amount}}",
      },
      {
        label: "Payment method (i.e. credit card)",
        value: "{{paymentMethodType}}",
      },
      {
        label: "Payment method translated",
        value: "{{paymentMethodTypeTranslated}}",
      },
      {
        label: "Recurring start date",
        value: "{{recurringStartDate}}",
      },
      {
        label: "Recurring frequency",
        value: "{{recurringFrequency}}",
      },
      {
        label: "Recurring frequency translated",
        value: "{{recurringFrequencyTranslated}}",
      },
      {
        label: "Purpose name (name of chosen purpose)",
        value: "{{purposeName}}",
      },
      {
        label: "Agreement name",
        value: "{{agreementName}}",
      },
      {
        label: "Name",
        value: "{{name}}",
      },
      {
        label: "First name",
        value: "{{firstName}}",
      },
      {
        label: "Last Name",
        value: "{{lastName}}",
      },
      {
        label: "Email",
        value: "{{email}}",
      },
      {
        label: "Phone",
        value: "{{phone}}",
      },
      {
        label: "Address",
        value: "{{address}}",
      },
      {
        label: "Post code",
        value: "{{postCode}}",
      },
      {
        label: "City",
        value: "{{city}}",
      },
      {
        label: "Country code",
        value: "{{countryCode}}",
      },
      {
        label: "Message",
        value: "{{message}}",
      },
      {
        label: "Success return page url",
        value: "{{successReturnPageUrl}}",
      },
      {
        label: "Payment gateway transaction id",
        value: "{{paymentGatewayTransactionId}}",
      },
      {
        label: "Payment gateway subscription token",
        value: "{{paymentGatewaySubscriptionToken}}",
      },
    ],
  },
];

export default mergeTags;
