<template>
  <of-modal noContentPadding @closeModal="closeModal">
    <!-- Modal header title -->
    <template v-slot:modalHeaderTitle>
      <span class="opacity-50">
        {{ this.communication.collectionName }} / {{ this.eventTypeName }}
      </span>
      / Email
    </template>

    <!-- Modal header right -->
    <template v-slot:modalHeaderRight>
      <of-button
        sm
        transparent
        class="mr-6"
        :icon="'random'"
        @click="switchToStripo"
      >
        Switch to new editor
      </of-button>
      <of-button
        sm
        transparent
        class="mr-6"
        :icon="'code'"
        @click="this.showVariables = !this.showVariables"
      >
        Variables
      </of-button>
      <of-button
        :loading="this.savingEmail"
        :icon="this.saveEmailButtonIcon"
        id="saveEmail"
        sm
        class="ml-auto mr-0"
      >
        {{ this.saveButtonText }}
      </of-button>
    </template>

    <!-- Modal content -->
    <template v-slot:modalContent>
      <div class="preview-container-fluid relative h-full">
        <div class="grid grid-cols-12">
          <div class="col-span-6 h-full pt-6 pl-6">
            <div
              id="emailEditorCodemirror"
              class="overflow-hidden border rounded-tl-md rounded-bl-md"
            >
              <textarea id="codemirror" v-model="this.emailHtml"></textarea>
            </div>
          </div>
          <div class="col-span-6 h-full pt-6 pr-6">
            <div
              class="overflow-hidden border border-l-0 rounded-tr-md rounded-br-md h-full"
            >
              <iframe
                id="codemirrorpreview"
                style="padding: 15px; width: 100%; height: 100%"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="p-6">
          <h2>
            Text for email
            <span class="opacity-50"
              >(for email-clients without HTML support)</span
            >
          </h2>
          <textarea
            style="height: 200px; width: 100%"
            v-model="this.emailText"
            class="overflow-hidden rounded-md p-2 text-base focus:outline-none border"
          ></textarea>
        </div>
      </div>
      <app-variables-modal
        v-if="this.showVariables"
        @closeVariablesModal="closeVariablesModal"
      >
      </app-variables-modal>
    </template>
  </of-modal>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapActions } from "vuex";
import $ from "jquery";
// import mergeTags from '../../../../helpers/mergeTags';
import "codemirror/lib/codemirror.css";
import CodeMirror from "codemirror";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/htmlmixed/htmlmixed";
import AppVariablesModal from "../../AppVariablesModal.vue";
import toast from "../../../../../helpers/toast";
import StripoTemplates from "../../../../../helpers/stripo/stripoTemplates";
import eventTypes from "../../../../../helpers/communications/eventTypes";

export default {
  name: "AppEventTemplateEmailEditor",
  components: {
    AppVariablesModal,
  },
  props: {
    communication: {
      type: Object,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingStripo: true,
      loadingEmailPreview: true,
      savingEmail: false,
      saveEmailButtonText: "Save",
      saveEmailButtonIcon: "",
      emailHtml: this.template.emailHtml,
      emailText: this.template.emailText,
      showVariables: false,
    };
  },
  setup() {
    // Setup toast notification from '/src/helpers/toast.js'
    const { toastError, toastSuccess } = toast();
    return { toastError, toastSuccess };
  },
  computed: {
    saveButtonText() {
      if (this.savingEmail) {
        return "Saving";
      }

      return this.saveEmailButtonText;
    },

    /**
     * Get event type name
     */
    eventTypeName() {
      return eventTypes.find((x) => x.eventType === this.template.eventType)
        .name;
    },
  },
  emits: ["closeModal", "switchToStripo"],
  methods: {
    // Map actions (./store/modules/communications/templates/actions.js)
    ...mapActions({ updateTemplate: "communications/templates/update" }),

    /**
     * Close modal
     */
    switchToStripo() {
      if (
        window.confirm(
          "WARNING: This will clear the email. You will have to start from scratch."
        )
      ) {
        // Start spinner and button text
        this.savingEmail = true;

        // The values to update
        const updateInTemplate = {
          communicationCollectionGuid:
            this.template.communicationCollectionGuid,
          communicationTemplateGuid: this.template.communicationTemplateGuid,
          emailHtml: "",
          emailText: "",
          stripoHtml: StripoTemplates[0].html,
          stripoCss: StripoTemplates[0].css,
        };

        // Call update template action
        this.updateTemplate(updateInTemplate)
          .then((template) => {
            // Notify user of success
            this.toastSuccess("Switched to new editor");

            // Stop spinner and button text
            this.savingEmail = false;
            this.saveEmailButtonText = "Saved!";
            this.saveEmailButtonIcon = "check";

            // Change button text back to just save after 3 sec
            setTimeout(() => {
              this.saveEmailButtonText = "Save";
              this.saveEmailButtonIcon = "";
            }, 3000);

            // Let's switch to stripo!
            this.$emit("switchToStripo", template);
          })
          .catch((error) => {
            this.toastError(error);
          });
      }
    },

    /**
     * Close modal
     */
    closeModal() {
      this.$emit("closeModal");
    },

    /**
     * Close variables modal
     */
    closeVariablesModal() {
      this.showVariables = false;
    },
  },
  mounted() {
    const _this = this;
    let delay;

    // Initialize CodeMirror
    const editor = CodeMirror.fromTextArea(
      document.getElementById("codemirror"),
      {
        mode: "text/html",
        lineNumbers: true,
        line: true,
        lineWrapping: true,
      }
    );

    // Update preview
    function updatePreview() {
      // Find the preview frame
      const previewFrame = document.getElementById("codemirrorpreview");

      // Then find the preview
      const preview =
        previewFrame.contentDocument || previewFrame.contentWindow.document;

      // Get value from editor and insert it into preview
      preview.open();
      preview.write(editor.getValue());
      preview.close();
    }

    // When CodeMirror changes
    editor.on("change", () => {
      clearTimeout(delay);
      delay = setTimeout(updatePreview, 300);
    });

    // Run updatePreview after 300 on init
    setTimeout(updatePreview, 300);

    // Click "Save" button
    $(document).on("click", "#saveEmail", () => {
      // Start spinner and button text
      _this.savingEmail = true;

      // The values to update
      const updateInTemplate = {
        communicationCollectionGuid: _this.template.communicationCollectionGuid,
        communicationTemplateGuid: _this.template.communicationTemplateGuid,
      };

      // Get the value from editor
      updateInTemplate.emailHtml = editor.getValue();
      updateInTemplate.emailText = this.emailText;

      // Call update template action
      _this
        .updateTemplate(updateInTemplate)
        .then(() => {
          // Notify user of success
          this.toastSuccess("Email saved");

          // Stop spinner and button text
          _this.savingEmail = false;
          _this.saveEmailButtonText = "Saved!";
          _this.saveEmailButtonIcon = "check";

          // Change button text back to just save after 3 sec
          setTimeout(() => {
            _this.saveEmailButtonText = "Save";
            _this.saveEmailButtonIcon = "";
          }, 3000);
        })
        .catch((error) => {
          this.toastError(error);
          this.loadingCreate = false;
        });
    });
  },
};
</script>

<style>
#emailEditorCodemirror .CodeMirror {
  height: 600px !important;
}
</style>
