<template>
  <div
    class="wrap"
    :class="{
      'bg-white fixed inset-x-20 inset-y-32 z-999 rounded-md shadow': this.modal,
    }"
  >
    <div
      v-if="hasHeaderTitle || hasHeaderRight"
      class="py-3 px-4 flex"
      :class="{
        'border-b border-solid border-black border-opacity-10': this.modal,
        'px-4': this.headerNarrow,
      }"
    >
      <h2 v-if="hasHeaderTitle" class="font-bold text-lg">
        <slot name="wrapHeaderTitle"></slot>
      </h2>
      <div v-if="hasHeaderRight" class="flex-grow text-right">
        <slot name="wrapHeaderRight"></slot>
        <div
          v-if="this.modal"
          @click="closeModal"
          class="cursor-pointer p-5 border-l -my-3 -mr-5 ml-5 border-black border-opacity-10 border-solid inline-block"
        >
          <fas icon="times" />
        </div>
      </div>
    </div>
    <div class="content" :class="this.padding">
      <slot name="wrapContent"></slot>
    </div>
  </div>
  <div @click="closeModal" v-if="this.modal" class="of-modal-bg fadeIn"></div>
</template>

<script>
export default {
  name: "OfWrap",
  props: {
    modal: Boolean,
    modalNarrow: Boolean,
    headerNarrow: Boolean,
    padding: {
      type: String,
      default: "p-4",
    },
  },
  computed: {
    hasHeaderTitle() {
      return !!this.$slots.wrapHeaderTitle;
    },
    hasHeaderRight() {
      return !!this.$slots.wrapHeaderRight;
    },
  },
};
</script>
