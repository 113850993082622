<template>
  <button
    class="h-8 leading-8 px-4 rounded"
    :disabled="loading"
    :class="{
      'bg-gradient-to-tl from-green-400 to-green-500':
        !transparent && !danger && !secondary,
      'border border-black border-solid border-opacity-10': transparent,
      '': secondary,
      '': clear,
      '': archive,
      '': danger,
      '': xs,
      '': sm,
      'rounded-r-none border-r-0': leftGroup,
      'rounded-l-none': rightGroup,
      'inline-block': inlineBlock,
    }"
  >
    <fas
      v-if="computedIcon"
      :class="{
        'animate-spin': loading || spin,
        '-ml-2 mr-2': sm || xs,
        '-ml-2 mr-3': !sm && !xs,
      }"
      :icon="computedIcon"
    />
    <slot> </slot>
  </button>
</template>

<script>
export default {
  name: "OfButtonCustom",
  props: {
    xs: Boolean,
    sm: Boolean,
    secondary: Boolean,
    transparent: Boolean,
    clear: Boolean,
    archive: Boolean,
    danger: Boolean,
    spin: Boolean,
    loading: Boolean,
    leftGroup: Boolean,
    rightGroup: Boolean,
    inlineBlock: Boolean,
    icon: {
      type: String,
      default: "",
      required: false,
    },
  },
  computed: {
    computedIcon() {
      if (this.loading) {
        return "circle-notch";
      }
      if (this.icon !== "") {
        return this.icon;
      }
      return null;
    },
  },
};
</script>
