<template>
  <!-- Modal -->
  <div
    class="fixed inset-x-10 mx-auto z-30 bg-white rounded shadow-lg"
    :style="[
      narrow ? { maxWidth: '600px' } : '',
      !narrow ? { 'inset-y-5': '600px' } : '',
    ]"
    :class="{ 'modal-narrow top-5': narrow, 'inset-y-5': !narrow }"
  >
    <template v-if="hasHeaderTitle">
      <div class="flex p-3 border-b">
        <h2 v-if="hasHeaderTitle" class="header font-bold text-lg ml-2">
          <slot name="modalHeaderTitle"></slot>
        </h2>
        <div v-if="hasHeaderRight" class="h-full flex ml-auto content-center">
          <div class="flex flex-wrap content-center">
            <slot name="modalHeaderRight"></slot>
          </div>
          <div
            class="cursor-pointer pl-5 pr-2"
            v-if="this.showCloseModalButton"
            @click="closeModal"
          >
            <fas icon="times" />
          </div>
        </div>
      </div>
    </template>
    <div
      class="relative flex-grow p-5"
      :class="{ 'p-0': noContentPadding }"
    >
      <slot name="modalContent"></slot>
    </div>
  </div>
  <div @click="closeModal" 
    class="fixed w-full h-full bg-black opacity-70 z-20 hover:cursor-pointer"
    style="top:0;left:0;"></div>
</template>

<script>
export default {
  name: "OfModal",
  inheritAttrs: false,
  props: {
    narrow: Boolean,
    noContentPadding: Boolean,
    showCloseModalButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasHeaderTitle() {
      return !!this.$slots.modalHeaderTitle;
    },
    hasHeaderRight() {
      return !!this.$slots.modalHeaderRight;
    },
  },
  emits: ["closeModal"],
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
