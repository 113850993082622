<template>
  <textarea
    type="text"
    :value="modelValue"
    @input="updateInput"
    :disabled="disabled"
    :placeholder="placeholder"
    class="focus:outline-none focus:ring-0 rounded focus:shadow-none border"
    :class="{
      'border-none border-b rounded-none bg-transparent p-1': simple,
      'p-2': !simple,
      'w-auto': widthAuto,
      'w-full': !widthAuto,
    }"
  />
</template>

<script>
export default {
  name: "OfInputText",
  props: {
    placeholder: String,
    transparent: Boolean,
    disabled: Boolean,
    simple: Boolean,
    widthAuto: Boolean,
    modelValue: String,
  },
  methods: {
    updateInput(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>
