<template>
  <div
    class="row grid gap-4 first:p-5 text-sm transition-all"
    :class="[
      true ? Padding : '',
      HasBody ? '' : '',
      hover ? HoverBackgroundColor : '',
      (BorderColor != '') ? BorderColor: '',
      borderBottom ? 'border-bottom' : '',
      showBody ? BackgroundColor : '',
      cols ? GridCols : '',
      sm ? SmGridCols : '',
      md ? MdGridCols : '',
      lg ? LgGridCols : '',
      xl ? XlGridCols : '',
      xxl ? XxlGridCols : '',
      hasButton ? 'has-button' : '',
    ]"
    :style="{
      noOverflow: 'overflow:initial',
    }"
    @click="clickRow"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <slot></slot>
  </div>
  <template v-if="showTemplate && this.$slots.body">
    <slide-up-down
      @close-end="closeEnd"
      v-model="showBody"
      :duration="300"
      class="grid grid-cols-1"
      :class="[BackgroundColor, BorderColor]"
    >
      <slot name="body"></slot>
    </slide-up-down>
  </template>
</template>

<script>
import { mapState } from 'vuex';
import SlideUpDown from "vue3-slide-up-down";
import visuals from "./../../../libs/visuals";

export default {
  name: "OfRow",
  components: {
    SlideUpDown,
  },
  emits: ["mouseEnter", "mouseLeave", "showBody"],
  props: {
    sm: Number,
    md: Number,
    lg: Number,
    xl: Number,
    xxl: Number,
    hover: Boolean,
    hasButton: Boolean,
    borderBottom: Boolean,
    noOverflow: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: String,
      default: "12",
    },
    background: {
      type: String,
    },
  },
  data() {
    return {
      showTemplate: false,
      showBody: false,
    };
  },
  computed: {
    ...mapState(["visual"]),
    Padding() {
      return visuals[this.visual].row.padding;
    },
    GridCols() {
      if (parseInt(this.cols, 10) > 12) {
        return "grid-cols-12";
      }

      return `grid-cols-${this.cols}`;
    },
    BorderLeft() {
      if (this.background) {
        return ``;
      }

      return '';
    },
    BorderColor() {
      if (this.background) {
        return `border-${this.background}-300 border-l-4`;
      }

      return 'border-transparent border-l-4';
    },
    BackgroundColor() {
      if (this.background) {
        return `bg-${this.background}-100`;
      }

      return 'bg-gray-100';
    },
    HoverBackgroundColor() {
      if (!this.hover) {
        return '';
      }

      if (this.background) {
        return `hover:cursor-pointer hover:bg-${this.background}-100`;
      }

      return 'hover:cursor-pointer hover:bg-gray-100';
    },
    SmGridCols() {
      return `sm:grid-cols-${this.sm}`;
    },
    MdGridCols() {
      return `md:grid-cols-${this.md}`;
    },
    LgGridCols() {
      return `lg:grid-cols-${this.lg}`;
    },
    XlGridCols() {
      return `xl:grid-cols-${this.xl}`;
    },
    XxlGridCols() {
      return `xxl:grid-cols-${this.xxl}`;
    },
    HasBody() {
      return !!this.$slots.body;
    },
  },
  methods: {
    mouseEnter() {
      this.$emit("mouseEnter");
    },
    mouseLeave() {
      this.$emit("mouseLeave");
    },
    clickRow() {
      if (this.$slots.body) {
        this.$emit("showBody");
        const _this = this;

        if (!this.showBody) {
          _this.showTemplate = true;
          setTimeout(function() {
            _this.showBody = true;
          }, 10)
        }
        else {
          _this.showBody = false;
        }
      }
    },
    closeEnd() {
      this.showTemplate = false;
    }
  }
};
</script>
