<template>
  <app-subscription-list shadow="shadow" :subscriptions="subscriptions" :loadContact="true" />
</template>

<script>
import axios from "axios";
import AppSubscriptionList from './../components/app/subscriptions/AppSubscriptionList.vue';

export default {
  name: "Subscriptions",
  components: {
    AppSubscriptionList,
  },
  data() {
      return {
          subscriptions: null,
      };
  },
  created() {
    this.getSubscriptions();
  },
  methods: {
    getSubscriptions() {
      axios.get(`${this.$store.state.OFconfig.api_payment_engine_url}/subscriptions?pageNumber=1&pageSize=20`, {
          headers: {
            Authorization: this.$store.state.OFconfig.api_payment_engine_token,
          }
        })
        .then((response) => {
          this.subscriptions = response.data.list;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
};
</script>
