<template>
  <div id="main-menu"
    class="fixed h-full w-16 hover:w-64 transition-all shadow-sm hover:shadow-lg overflow-x-hidden"
    @mouseenter="show = true" @mouseleave="show = false">
    <div class="h-full w-64 relative overflow-y-auto pb-4 pt-4 text-white">
      <ul>
        <of-menu-item icon="home" url="/">Dashboard</of-menu-item>
        <of-menu-header :show="Show">{{ $t("leftMenu.Current Givers") }}</of-menu-header>
        <of-menu-item url="/subscriptions" icon="redo">{{ $t("leftMenu.Subscriptions") }}</of-menu-item>
        <of-menu-item url="/payments" icon="credit-card">{{ $t("leftMenu.Payments") }}</of-menu-item>
        <of-menu-item url="/contacts" icon="users">{{ $t("leftMenu.Contacts") }}</of-menu-item>
        <!-- <of-menu-item icon="database">{{ $t("leftMenu.Data") }}</of-menu-item> -->
        <of-menu-header :show="Show">{{ $t("leftMenu.Recruiting") }}</of-menu-header>
        <of-menu-item icon="tools">
          {{ $t("leftMenu.Form Builder") }}
          <template v-slot:sub>
            <of-menu-item>{{ $t("leftMenu.Forms") }}</of-menu-item>
            <of-menu-item>{{ $t("leftMenu.Customisation") }}</of-menu-item>
          </template>
        </of-menu-item>
        <of-menu-item icon="receipt" url="/receipts">
          {{ $t("leftMenu.Receipts") }}
        </of-menu-item>
        <!-- <of-menu-item icon="phone">{{ $t("leftMenu.Onboarding") }}</of-menu-item> -->
        <!-- <of-menu-header :show="Show">{{ $t("leftMenu.SMS") }}</of-menu-header>
        <of-menu-item icon="hashtag">{{ $t("leftMenu.Keywords") }}</of-menu-item>
        <of-menu-item icon="bullhorn">{{ $t("leftMenu.Campaigns") }}</of-menu-item>
        <of-menu-item icon="comments-dollar">{{ $t("leftMenu.Receiving SMS") }}</of-menu-item>
        <of-menu-item icon="comment-dots">{{ $t("leftMenu.Send Bulk SMS") }}</of-menu-item>
        <of-menu-item icon="exclamation-circle">{{ $t("leftMenu.Failed Transactions") }}</of-menu-item> -->
        <of-menu-header :show="Show">{{ $t("leftMenu.Other") }}</of-menu-header>
        <of-menu-item icon="file-export">{{ $t("leftMenu.Export") }}</of-menu-item>
        <!-- <of-menu-item icon="cogs">{{ $t("leftMenu.Settings") }}</of-menu-item>
        <of-menu-item icon="users-cog">{{ $t("leftMenu.Users") }}</of-menu-item> -->
        <of-menu-item icon="medkit">{{ $t("leftMenu.Support") }}</of-menu-item>
        <!-- <of-menu-header :show="Show">{{ $t("Global.Language") }}</of-menu-header>
        <select
          class="text-black ml-6 py-1 px-2 rounded-sm"
          name="language"
          v-model="language"
          @change="setLanguage($event)">
          <option value="en">{{ $t("Global.English") }}</option>
          <option value="da">{{ $t("Global.Danish") }}</option>
        </select> -->
        <of-menu-header :show="Show">Visuals</of-menu-header>
        <of-menu-item icon="layer-group" @click="setVisualDefault">Default</of-menu-item>
        <of-menu-item icon="layer-group" @click="setVisualCompact">Compact</of-menu-item>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import OfMenuHeader from "./OfMenuHeader.vue";
import OfMenuItem from "./OfMenuItem.vue";

export default {
  name: "OfMenu",
  components: {
    OfMenuHeader,
    OfMenuItem,
  },
  data() {
    return {
      show: false,
      language: "en",
    };
  },
  computed: {
    Show() {
      return this.show;
    }
  },
  methods: {
    ...mapActions({
      setVisual: "setVisual",
    }),
    setLanguage(event) {
      this.$i18n.locale = event.target.value;
    },
    setVisualDefault() {
      this.setVisual('default');
    },
    setVisualCompact() {
      this.setVisual('compact');
    },
  }
};
</script>
