<template>
  <!-- Event row with toggle -->
  <of-row
    hover
    @mouseEnter="mouseEnter"
    @mouseLeave="mouseLeave"
  >
    <!-- Event name -->
    <div class="col-span-6">
      <p class="mt-0.5 mb-0.5 text-sm">
        {{ EventHeader }}
      </p>
    </div>

    <!-- Using info -->
    <div
      class="col-span-6 text-right text-sm"
      v-if="this.eventType.supported"
      :class="{
        'bg-gradient-to-r animate-skeleton rounded-lg bg-gray-200':
          isLoadingInheritedTemplate,
        'from-gray-200 to-gray-200 via-gray-100 bg-no-repeat':
          isLoadingInheritedTemplate,
      }"
    >
      <div class="text-right inline-flex justify-center items-center">
        <div
          class="mr-4 opacity-40"
          v-if="this.inheritCollectionName && this.inheritCollectionName != ''"
        >
          <span v-if="ShowEventInfoInheritText">Using receipt from </span><i class="italic">{{ this.inheritCollectionName }}</i>
        </div>
        <div
          v-if="this.eventType.eventType === 'PaymentRecurringPending'"
          class="mr-2 of-icon of-icon-sm"
        >
          <div class="bs-icon"></div>
        </div>
        <div
          v-if="this.eventType.eventType === 'PaymentRecurringPending'"
          class="mr-2 of-icon of-icon-sm"
        >
          <div class="mp-icon"></div>
        </div>
        <div
          v-if="this.eventType.eventType !== 'PaymentRecurringPending'"
          class="mr-2 of-icon of-icon-sm"
          :class="{ 'of-icon-inactive': !this.isEmailActive }"
        >
          <fas icon="envelope" />
        </div>
        <div class="of-icon of-icon-sm" :class="{ 'of-icon-inactive': !this.isSmsActive }">
          <fas icon="sms" />
        </div>
      </div>
    </div>

    <!-- Event body -->
    <template v-slot:body v-if="this.eventType.supported">
      <div class="flex flex-col" >
        <!-- Event description -->
        <p
          v-if="!this.isNoParentDefault"
          class="text-sm mt-1 mb-2 ml-4 mr-4 opacity-50"
        >
          {{ EventDescription }}
        </p>

        <div v-if="isNoParentDefault" class="px-4">
          <div
            class="bg-red-500 6 p-6 transition-all duration-500 rounded-xl rounded-tl-none rounded-tr-none"
          >
            <p class="text-center text-lg text-white">
              This event has no template. Please click
              <of-button transparent xs @click="NewEmptyTemplate"
                >HERE</of-button
              >
              to create a template.
            </p>
          </div>
        </div>

        <!-- Templates -->
        <transition name="fade">
          <div
            v-if="!isNoParentDefault && this.showEventBody"
            class="p-4 mb-2 rounded flex-grow flex flex-col border border-opacity-0 transition-all duration-500"
            :class="{
              'mt-4 bg-gray-100': isInherited,
              'border border-opacity-5': isInherited,
            }"
          >
            <div
              class="text-center text-gray-400 h-0 overflow-hidden transition-all duration-500"
              :class="{ 'overflow-auto': isInherited, 'h-12': isInherited }"
            >
              Using receipt from
              <strong>{{ this.inheritCollectionName }}</strong>
              <of-button class="ml-4" transparent xs @click="NewTemplate">
                Create new receipt
              </of-button>
            </div>
            <div class="grid grid-cols-3 gap-4 h-full">
              <app-communication-event-template
                v-if="this.eventType.inputs.indexOf('screen') > -1"
                :template="templateOrInheritedTemplate"
                :isInherited="isInherited"
                :showingEventBody="this.showEventBody"
                :isActive="true"
                :inputType="'screen'"
                @editTemplateScreenHtml="editTemplateScreenHtml"
              />
              <app-communication-event-template
                v-if="this.eventType.inputs.indexOf('email') > -1"
                :template="templateOrInheritedTemplate"
                :isInherited="isInherited"
                :isActive="isEmailActive"
                :showingEventBody="this.showEventBody"
                :inputType="'email'"
                @editTemplateEmail="editTemplateEmail"
                @updateTemplateSendAs="updateTemplateSendAs"
              />
              <app-communication-event-template
                v-if="this.eventType.inputs.indexOf('sms') > -1"
                :template="templateOrInheritedTemplate"
                :isInherited="isInherited"
                :showingEventBody="this.showEventBody"
                :isActive="isSmsActive"
                :inputType="'sms'"
                @editTemplateSms="editTemplateSms"
                @updateTemplateSendAs="updateTemplateSendAs"
              />
              <app-communication-event-template
                v-if="this.eventType.inputs.indexOf('bs') > -1"
                :template="templateOrInheritedTemplate"
                :isInherited="isInherited"
                :showingEventBody="this.showEventBody"
                :isActive="isSmsActive"
                :inputType="'bs'"
                @editTemplateBs="editTemplateBs"
                @updateTemplateSendAs="updateTemplateSendAs"
              />
              <app-communication-event-template
                v-if="this.eventType.inputs.indexOf('mp') > -1"
                :template="templateOrInheritedTemplate"
                :isInherited="isInherited"
                :showingEventBody="this.showEventBody"
                :isActive="isSmsActive"
                :inputType="'mp'"
                @editTemplateMp="editTemplateMp"
                @updateTemplateSendAs="updateTemplateSendAs"
              />
            </div>

            <!-- Copy/Paste -->
            <div
              v-if="this.eventType.eventType !== 'PaymentRecurringPending'"
              class="mt-4 pb-4 text-center flex items-center justify-items-center m-auto"
            >
              <of-button @click="CopyTemplate" xs transparent class="mr-1"
                >Copy</of-button
              >
              <of-button
                xs
                transparent
                @click="PasteTemplate"
                :disabled="!this.hasCopiedTemplate"
                >Paste</of-button
              >
              <p class="text-sm opacity-30 ml-2">
                receipt
              </p>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </of-row> 
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import AppCommunicationEventTemplate from "./AppCommunicationEventTemplate.vue";
import toast from "../../../../helpers/toast";
import StripoTemplates from "../../../../helpers/stripo/stripoTemplates";
import OfButton from "../../../shared/button/OfButton.vue";

export default {
  name: "AppCommunicationEvent",
  components: {
    AppCommunicationEventTemplate,
    OfButton,
  },
  props: {
    eventType: {
      type: Object,
      required: true,
    },
    template: {
      type: Object,
      required: false,
    },
    communication: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "editTemplateEmail",
    "editTemplateScreenHtml",
    "editTemplateSms",
    "editTemplateBs",
    "editTemplateMp",
  ],
  data() {
    return {
      showEventBody: true,
      showEventInfoInheritText: false,
      loadingTemplate: true,
      isNoParentDefault: false,
      isIgnoreTemplate: false,
    };
  },
  setup() {
    // Setup toast notification from '/src/helpers/toast.js'
    const { toastError, toastSuccess } = toast();
    return { toastError, toastSuccess };
  },
  computed: {
    ...mapGetters({
      getCommunication: "communications/getCommunication",
      getCopiedTemplateScreen:
        "communications/templates/getCopiedTemplateScreen",
      getCopiedTemplateEmail: "communications/templates/getCopiedTemplateEmail",
      getCopiedTemplateSMS: "communications/templates/getCopiedTemplateSMS",
    }),

    EventHeader() {
      return this.$t(`Communication.Event.${this.eventType.eventType}.Name`);
    },

    EventDescription() {
      return this.$t(`Communication.Event.${this.eventType.eventType}.Description`);
    },

    ShowEventInfoInheritText() {
      return this.showEventInfoInheritText;
    },

    /**
     * Get sms text
     */
    smsText() {
      if (this.template) {
        if (this.template.smsText) {
          return this.template.smsText;
        }
      }

      return "";
    },

    /**
     * Get bs text
     */
    bsText() {
      if (this.template) {
        if (this.template.bsText) {
          return this.template.bsText;
        }
      }

      return "";
    },

    /**
     * Check if loading
     */
    isLoadingInheritedTemplate() {
      // NOTE: 'test' needs to be there!!! It is used for when loading a new template
      // eslint-disable-next-line
      let test = this.templateOrInheritedTemplate;
      return (
        !this.template &&
        this.inheritCollectionName === "" &&
        !this.isNoParentDefault &&
        !this.isIgnoreTemplate
      );
    },

    /**
     * Get either template or inherited template
     */
    templateOrInheritedTemplate() {
      // Return null if there is no template and no parent guid
      if (!this.template && !this.communication.parentGuid) {
        return null;
      }

      // If there is an inherited template, return it
      if (this.inheritedTemplate) {
        return this.inheritedTemplate;
      }

      // Finally return template
      return this.template;
    },

    /**
     * Find the inherited template
     */
    inheritedTemplate() {
      // If we already have a template, we aren't inheriting
      if (this.template) {
        // eslint-disable-next-line
        this.isNoParentDefault = false;
        return null;
      }

      // Only search if there is no template and the communication has a parent
      if (!this.template && this.communication.parentGuid) {
        const template = this.getInheritedTemplate(
          this.communication.parentGuid
        );
        return template;
      }

      return null;
    },

    /**
     * Get the collection name of inherited
     */
    inheritCollectionName() {
      // Only search if there is no template and we have an inherited template
      if (!this.template && this.inheritedTemplate) {
        // Let's find the parent collection from collections
        const parentCollection = this.getCommunication(
          this.inheritedTemplate.communicationCollectionGuid
        );

        // Return parent collection if found
        if (parentCollection) {
          return parentCollection.collectionName;
        }
      }

      return "";
    },

    /**
     * Is it inherited?
     */
    isInherited() {
      return this.inheritCollectionName !== "";
    },

    /**
     * Is email set to send?
     */
    isEmailActive() {
      // Check in template
      if (this.template) {
        return this.template.sendAsEmail;
      }

      // Check in inherited template
      if (this.inheritedTemplate) {
        return this.inheritedTemplate.sendAsEmail;
      }

      return false;
    },

    /**
     * Is SMS set to send?
     */
    isSmsActive() {
      // Check in template
      if (this.template) {
        return this.template.sendAsSms;
      }

      // Check in inherited template
      if (this.inheritedTemplate) {
        return this.inheritedTemplate.sendAsSms;
      }

      return false;
    },

    hasCopiedTemplate() {
      return this.getCopiedTemplateScreen != null;
    },
  },
  methods: {
    // Map actions (./store/modules/communications/templates/actions.js)
    ...mapActions({
      getTemplates: "communications/templates/get",
      createTemplate: "communications/templates/create",
      updateTemplate: "communications/templates/update",
    }),

    ...mapMutations({
      updateCopiedTemplate: "communications/templates/UPDATE_COPIED_TEMPLATE",
    }),

    mouseEnter() {
      this.showEventInfoInheritText = true;
    },

    mouseLeave() {
      this.showEventInfoInheritText = false;
    },

    /**
     * Update send
     */
    updateTemplateSendAs(templateEventType, templateEventTypeValue) {
      // Prepare values
      const sendAsEmailValue =
        templateEventType === "Email" ? templateEventTypeValue : null;
      const sendAsSmsValue =
        templateEventType === "SMS" ? templateEventTypeValue : null;

      // The values to update
      const updateInTemplate = {
        communicationCollectionGuid:
          this.communication.communicationCollectionGuid,
        communicationTemplateGuid: this.template.communicationTemplateGuid,
        sendAsEmail: sendAsEmailValue,
        sendAsSms: sendAsSmsValue,
      };

      // Call update template action
      this.updateTemplate(updateInTemplate)
        .then(() => {
          let successMessage = "";

          if (templateEventTypeValue) {
            successMessage = `${templateEventType} active`;
          } else {
            successMessage = `${templateEventType} inactive`;
          }

          // Notify user of success
          this.toastSuccess(successMessage);
        })
        .catch((error) => {
          this.toastError(error);
        });
    },

    /**
     * Email
     */
    editTemplateEmail(template) {
      this.$emit("editTemplateEmail", template);
    },

    /**
     * Screen html
     */
    editTemplateScreenHtml(template) {
      this.$emit("editTemplateScreenHtml", template);
    },

    /**
     * Template SMS
     */
    editTemplateSms(template) {
      this.$emit("editTemplateSms", template);
    },

    /**
     * Template BS
     */
    editTemplateBs(template) {
      this.$emit("editTemplateBs", template);
    },

    /**
     * Template MP
     */
    editTemplateMp(template) {
      this.$emit("editTemplateMp", template);
    },

    /**
     * New template
     */
    NewTemplate() {
      // eslint-disable-next-line
      if (
        window.confirm(
          "Are you sure that you want to create a new template? NOTE: This cannot be undone!"
        )
      ) {
        this.newTemplate = this.inheritedTemplate;
        // eslint-disable-next-line
        this.newTemplate.communicationCollectionGuid = this.communication.communicationCollectionGuid;

        // Call create template action
        this.createTemplate(this.newTemplate)
          .then(() => {
            this.toastSuccess("New template created");
          })
          .catch((error) => {
            this.toastError(error);
          });
      }
    },

    /**
     * New template
     */
    NewEmptyTemplate() {
      this.newTemplate = {
        communicationCollectionGuid:
          this.communication.communicationCollectionGuid,
        emailHtml: "",
        emailSenderEmail: "",
        emailSenderName: "",
        emailSubject: "",
        stripoHtml: StripoTemplates[0].html,
        stripoCss: StripoTemplates[0].css,
        eventType: this.eventType.eventType,
      };

      // Call create template action
      this.createTemplate(this.newTemplate)
        .then(() => {
          this.isNoParentDefault = false;

          // Notify user of success
          this.toastSuccess("New template created");
        })
        .catch((error) => {
          this.toastError(error);
        });
    },

    CopyTemplate() {
      this.updateCopiedTemplate(this.template);
      this.toastSuccess("On screen html, email and SMS copied");
    },

    PasteTemplate() {
      const copiedScreen = this.getCopiedTemplateScreen;
      const copiedEmail = this.getCopiedTemplateEmail;
      const copiedSMS = this.getCopiedTemplateSMS;

      // The values to update
      const updateInTemplate = {
        communicationCollectionGuid:
          this.communication.communicationCollectionGuid,
        communicationTemplateGuid: this.template.communicationTemplateGuid,
        screenHtml: copiedScreen.screenHtml,
        emailHtml: copiedEmail.emailHtml,
        emailSenderEmail: copiedEmail.emailSenderEmail,
        emailSenderName: copiedEmail.emailSenderName,
        emailSubject: copiedEmail.emailSubject,
        smsSenderAlias: copiedSMS.smsSenderAlias,
        smsText: copiedSMS.smsText,
      };

      if (
        confirm(
          "WARNING: Pasting the template will overwrite your current template. Are you sure you want to continue?"
        )
      ) {
        // Call update template action
        this.updateTemplate(updateInTemplate)
          .then(() => {
            // Notify user of success
            this.toastSuccess("Template successfully copied");
          })
          .catch((error) => {
            this.toastError(error);
          });
      }
    },

    /**
     * Update send
     */
    // eslint-disable-next-line consistent-return
    getInheritedTemplate(parentGuid) {
      if (!this.eventType.supported) {
        this.loadingTemplate = false;
        this.isNoParentDefault = false;
        this.isIgnoreTemplate = true;
        return null;
      }

      const parentCollection = this.getCommunication(parentGuid);

      // Does the parent collection exist?
      if (parentCollection) {
        // Does the parent have loaded templates?
        if (parentCollection.templates) {
          // Find the template by eventType
          const template = parentCollection.templates.find(
            (x) => x.eventType === this.eventType.eventType
          );

          // If there is no template, run again with parent
          if (!template && parentCollection.parentGuid) {
            return this.getInheritedTemplate(parentCollection.parentGuid);
          }

          // If no template and the parent collection is the root with no default set
          if (
            !template &&
            !parentCollection.parentGuid &&
            !parentCollection.defaultCollection
          ) {
            this.loadingTemplate = false;
            this.isNoParentDefault = true;
            return undefined;
          }

          // If there is not template and no parent
          if (!template && !parentCollection.parentGuid) {
            this.isNoParentDefault = false;
            this.loadingTemplate = false;
            return undefined;
          }

          // Return the parent collection name
          this.isNoParentDefault = false;
          this.loadingTemplate = false;
          this.parentCollectionTemplate = template;
          return this.parentCollectionTemplate;
        }

        // Did we already try to fetch the templates?
        if (parentCollection.fetchedTemplates) {
          // We shouldn't be loading any more
          this.loadingTemplate = false;
          this.isNoParentDefault = false;
        } else {
          parentCollection.fetchedTemplates = true;
          this.isNoParentDefault = false;

          this.getTemplates(parentCollection.communicationCollectionGuid).then(
            () =>
              this.getInheritedTemplate(
                parentCollection.communicationCollectionGuid
              )
          );
        }
      } else {
        this.loadingTemplate = false;
        this.isNoParentDefault = false;
        return null;
      }
    },
  },
};
</script>
