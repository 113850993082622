/* eslint no-shadow: ["error", { "allow": ["getters"] }] */
// GETTERS
const getters = {
    /**
     * Get copied template screen
     */
    getCopiedTemplateScreen: (state) => state.copiedTemplateScreen,

    /**
     * Get copied template email
     */
    getCopiedTemplateEmail: (state) => state.copiedTemplateEmail,

    /**
     * Get copied template sms
     */
    getCopiedTemplateSMS: (state) => state.copiedTemplateSMS,
};

export default getters;
