/* eslint-disable no-shadow */
import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import templates from './templates';

export default {
    namespaced: true,
    modules: {
        templates,
    },
    state,
    getters,
    actions,
    mutations,
};