import axios from 'axios';
import md5 from 'js-md5';

const actions = {

    /**
     * create template
     */
    async create({ commit }, inheritTemplate) {
        return new Promise((resolve, reject) => {
            const json = JSON.stringify(inheritTemplate);

            axios.post(
                    `${this.state.apiUrl}/communicationTemplate`,
                    inheritTemplate, {
                        headers: {
                            Authorization: this.state.apiToken,
                            requestMD5: md5(json),
                        },
                    },
                )
                .then((response) => {
                    commit('communications/ADD_COMMUNICATION_TEMPLATE', response.data, { root: true });
                    resolve();
                })
                .catch((error) => reject(error));
        });
    },

    /**
     * Get template
     */
    async get({ commit }, communicationCollectionGuid) {
        // Let's get the templates
        axios.get(
                `${this.state.apiUrl}/communicationCollection/${communicationCollectionGuid}/templates`, { headers: { Authorization: this.state.apiToken } },
            )
            .then((response) => {
                commit(
                    'communications/SET_COMMUNICATION_TEMPLATES', {
                        communicationCollectionGuid,
                        templates: response.data,
                    }, { root: true },
                );
            });
    },

    /**
     * Update template
     */
    async update({ commit }, template, ) {
        // Body
        let body = [];

        if (template.emailHtml || template.emailHtml === '') {
            body.push({
                op: 'replace',
                path: '/emailHtml',
                value: template.emailHtml,
            });
        }

        if (template.emailText || template.emailText === '') {
            body.push({
                op: 'replace',
                path: '/emailText',
                value: template.emailText,
            });
        }

        if (template.stripoHtml) {
            body.push({
                op: 'replace',
                path: '/stripoHtml',
                value: template.stripoHtml,
            });
        }

        if (template.stripoCss) {
            body.push({
                op: 'replace',
                path: '/stripoCss',
                value: template.stripoCss,
            });
        }

        if (template.editorType) {
            body.push({
                op: 'replace',
                path: '/editorType',
                value: template.editorType,
            });
        }

        if (template.emailSenderEmail) {
            body.push({
                op: 'replace',
                path: '/emailSenderEmail',
                value: template.emailSenderEmail,
            });
        }

        if (template.emailSenderName) {
            body.push({
                op: 'replace',
                path: '/emailSenderName',
                value: template.emailSenderName,
            });
        }

        if (template.emailSubject) {
            body.push({
                op: 'replace',
                path: '/emailSubject',
                value: template.emailSubject,
            });
        }

        if (template.screenHtml) {
            body.push({
                op: 'replace',
                path: '/screenHtml',
                value: template.screenHtml,
            });
        }

        if (template.smsSenderAlias) {
            body.push({
                op: 'replace',
                path: '/smsSenderAlias',
                value: template.smsSenderAlias,
            });
        }

        if (template.smsText) {
            body.push({
                op: 'replace',
                path: '/smsText',
                value: template.smsText,
            });
        }

        if (template.sendAsEmail != null) {
            body.push({
                op: 'replace',
                path: '/sendAsEmail',
                value: template.sendAsEmail,
            });
        }

        if (template.sendAsSms != null) {
            body.push({
                op: 'replace',
                path: '/sendAsSms',
                value: template.sendAsSms,
            });
        }

        if (template.bsText != null) {
            body.push({
                op: 'replace',
                path: '/bsText',
                value: template.bsText,
            });
        }

        body = JSON.stringify(body);

        return new Promise((resolve, reject) => {
            axios.patch(
                    `${this.state.apiUrl}/communicationTemplate/${template.communicationTemplateGuid}`,
                    body, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: this.state.apiToken,
                            requestMD5: md5(body),
                        },
                    },
                )
                .then((response) => {
                    commit(
                        'communications/UPDATE_COMMUNICATION_TEMPLATE', {
                            communicationCollectionGuid: template.communicationCollectionGuid,
                            template: response.data,
                        }, { root: true },
                    );

                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    },
};

export default actions;