import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { createApp } from "vue";
  
import VueToast from 'vue-toast-notification';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./libs/i18n";
import 'vue-toast-notification/dist/theme-default.css';
import "./assets/tailwind.css";
import "./assets/of.css";

import OfMenu from "@/components/shared/menu/OfMenu.vue";
import OfButton from "./components/shared/button/OfButton.vue";
import OfButtonCustom from "./components/shared/button/OfButtonCustom.vue";
import OfInputText from "./components/shared/input/OfInputText.vue";
import OfInputTextarea from "./components/shared/input/OfInputTextarea.vue";
import OfInputCheckbox from "./components/shared/input/OfInputCheckbox.vue";
import OfWrap from "./components/shared/wrap/OfWrap.vue";
import OfWrapHeader from "./components/shared/wrap/OfWrapHeader.vue";
import OfModal from "./components/shared/modal/OfModal.vue";
import OfLoadingSpinnerAbsolute from "./components/shared/loading/OfLoadingSpinnerAbsolute.vue";

import OfRow from "./components/shared/layout/OfRow.vue";
import OfCard from "./components/shared/layout/OfCard.vue";

library.add(fas);

const app = createApp(App);

app.use(i18n);
app.use(store);
app.use(router);
app.use(VueToast, { position: 'top-right', duration: 6000 });
app.component("fas", FontAwesomeIcon);
app.component("of-menu", OfMenu);
app.component("of-button", OfButton);
app.component("of-button-custom", OfButtonCustom);
app.component("of-input-checkbox", OfInputCheckbox);
app.component("of-input-text", OfInputText);
app.component("of-input-textarea", OfInputTextarea);
app.component("of-wrap", OfWrap);
app.component("of-wrap-header", OfWrapHeader);
app.component("of-modal", OfModal);
app.component("of-loading-spinner-absolute", OfLoadingSpinnerAbsolute);
app.component("of-row", OfRow);
app.component("of-card", OfCard);
app.mount("#app");
