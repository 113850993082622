import { createI18n } from 'vue-i18n'

export default createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
      da: Object.assign(
          {}, 
          require('@/libs/i18n/locales/da.json'), 
          require('@/libs/i18n/locales/pages/communication/da.communication.json'),
      ),
      en: Object.assign(
          {}, 
          require('@/libs/i18n/locales/en.json'), 
          require('@/libs/i18n/locales/pages/communication/en.communication.json'),
      ),
  }
})