<template>
  <app-payment-list shadow="shadow" :payments="payments" :loadContact="true" />
</template>

<script>
import axios from "axios";
import AppPaymentList from './../components/app/payments/AppPaymentList.vue';

export default {
  name: "Payments",
  components: {
    AppPaymentList,
  },
  data() {
      return {
          payments: null,
      };
  },
  created() {
    this.getPayments();
  },
  methods: {
    getPayments() {
      axios.get(`${this.$store.state.OFconfig.api_payment_engine_url}/payments?pageNumber=1&pageSize=20`, {
          headers: {
            Authorization: this.$store.state.OFconfig.api_payment_engine_token,
          }
        })
        .then((response) => {
          this.payments = response.data.list;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
};
</script>
