<template>
  <div
    class="p-4 bg-white group relative rounded flex flex-col transition-all"
    :class="{
      'bg-gray-200 border shadow-inner': this.IsInherited,
      'shadow': !this.IsInherited,
    }"
  >
    <div class="flex">
      <div class="mr-4">
        <div
          class="of-icon of-icon-lg"
          :class="{ 'of-icon-inactive': !this.isActive }"
        >
          <div v-if="this.inputType === 'BS'" class="bs-icon"></div>
          <div v-if="this.inputType === 'MP'" class="mp-icon"></div>
          <fas v-else :icon="this.InputIcon" />
        </div>
      </div>
      <div>
        <p class="font-bold opacity-60 pb-1">
          {{ this.TemplateName }}
        </p>
        <p
          v-if="this.inputType === 'On screen html' || this.inputType === 'BS'"
          class="text-sm opacity-70"
        >
          Always active
        </p>

        <!-- Email Send checkbox -->
        <of-input-checkbox
          v-else-if="this.inputType === 'Email'"
          :disabled="this.IsInherited"
          :checked="this.isActive"
          v-model="this.isToggled"
          @change="updateTemplateSendAs"
          >Active</of-input-checkbox
        >

        <!-- SMS Send checkbox -->
        <of-input-checkbox
          v-else
          :disabled="this.IsInherited"
          :checked="this.isActive"
          v-model="this.isToggled"
          @change="updateTemplateSendAs"
          >Active</of-input-checkbox
        >
      </div>
    </div>
    <div class="flex-grow pt-4">
      <div
        class="w-full h-full overflow-none rounded-sm border flex flex-col"
      >
        <div>
          <template v-if="this.inputType === 'Email'">
            <p :class="this.pClass">
              <template v-if="this.EmailSenderEmail">
                {{ this.EmailSenderEmail }}
              </template>
              <template v-else>
                <span class="opacity-60">Sender email</span>
              </template>
            </p>
            <p :class="this.pClass">
              <template v-if="this.EmailSenderName">
                {{ this.EmailSenderName }}
              </template>
              <template v-else>
                <span class="opacity-60">Name</span>
              </template>
            </p>
            <p :class="this.pClass">
              <template v-if="this.EmailSubject">
                {{ this.EmailSubject }}
              </template>
              <template v-else>
                <span class="opacity-60">Subject</span>
              </template>
            </p>
          </template>
          <template v-if="this.inputType === 'SMS'">
            <p :class="this.pClass">
              <template v-if="this.SmsSenderAlias">
                {{ this.SmsSenderAlias }}
              </template>
              <template v-else>
                <span class="opacity-60">Sender</span>
              </template>
            </p>
          </template>
        </div>
        <div class="flex-grow" v-if="this.showingEventBody">
          <p class="p-2" v-if="this.inputType == 'SMS'">
            {{ this.SmsSenderText }}
          </p>
          <p class="p-2" v-else-if="this.inputType == 'BS'">
            {{ this.BsText }}
          </p>
          <p class="p-2" v-else-if="this.inputType == 'MP'">
            {{ this.MpText }}
          </p>
          <!-- Email Html -->
          <iframe
            v-else
            style="height: 100%; width: 100%"
            class="of-scrollbar"
            :srcdoc="Html"
          />
        </div>
      </div>
    </div>
    <of-button
      v-if="this.inputType == 'On screen html' && !IsInherited"
      :class="buttonClass"
      @click="editTemplateScreenHtml"
    >
      Edit
    </of-button>
    <of-button
      v-if="this.inputType == 'Email' && !IsInherited"
      :class="buttonClass"
      :disabled="this.IsInherited"
      @click="editTemplateEmail"
    >
      Edit
    </of-button>
    <of-button
      v-if="this.inputType == 'SMS' && !IsInherited"
      :class="buttonClass"
      :disabled="this.IsInherited"
      @click="editTemplateSms"
    >
      Edit
    </of-button>
    <of-button
      v-if="this.inputType == 'BS' && !IsInherited"
      :class="buttonClass"
      @click="editTemplateBs"
      :disabled="this.IsInherited"
    >
      Edit
    </of-button>
    <of-button
      v-if="this.inputType == 'MP' && !IsInherited"
      :class="buttonClass"
      @click="editTemplateMp"
      :disabled="this.IsInherited"
    >
      Edit
    </of-button>
  </div>
</template>

<script>
export default {
  name: "AppCommunicationEventTemplate",
  props: {
    template: {
      type: Object,
      required: false,
    },
    isInherited: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    showingEventBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isToggled: this.isActive,
      bsSvg: require("../../../../assets/img/bs-short-logo.svg"),
    };
  },
  emits: [
    "editTemplateEmail",
    "editTemplateScreenHtml",
    "updateTemplateSendAs",
    "editTemplateSms",
    "editTemplateBs",
    "editTemplateMp",
    "switchToStripo",
  ],
  methods: {
    updateTemplateSendAs() {
      this.$emit("updateTemplateSendAs", this.inputType, this.isToggled);
    },
    editTemplateEmail() {
      this.$emit("editTemplateEmail", this.template);
    },
    editTemplateScreenHtml() {
      this.$emit("editTemplateScreenHtml", this.template);
    },
    editTemplateSms() {
      this.$emit("editTemplateSms", this.template);
    },
    editTemplateBs() {
      this.$emit("editTemplateBs", this.template);
    },
    editTemplateMp() {
      this.$emit("editTemplateMp", this.template);
    },
    switchToStripo() {
      this.$emit("switchToStripo");
    },
  },
  computed: {
    /**
     * Return button class
     */
    buttonClass() {
      return "hidden group-hover:inline-block absolute bottom-2 left-1/2 transform -translate-x-1/2";
    },

    /**
     * The class used for p
     */
    pClass() {
      return "p-2 border-0 border-b";
    },

    /**
     * Get icon name for fontawesome
     */
    InputIcon() {
      if (this.inputType === "SMS") {
        return "sms";
      }

      if (this.inputType === "Email") {
        return "envelope";
      }

      return "desktop";
    },

    /**
     * Is this inherited?
     */
    IsInherited() {
      return this.isInherited;
    },

    /**
     * The html from the template
     */
    Html() {
      if (this.template) {
        if (this.inputType === "On screen html" && this.template.screenHtml) {
          return this.template.screenHtml;
        }
        if (this.inputType === "Email" && this.template.emailHtml) {
          return this.template.emailHtml;
        }
        if (this.inputType === "Sms" && this.template.smsText) {
          return this.template.smsText;
        }
      }
      return "";
    },

    /**
     * Get email sender email
     */
    EmailSenderEmail() {
      if (this.template && this.template.emailSenderEmail) {
        return this.template.emailSenderEmail;
      }

      return "";
    },

    /**
     * Get email sender name
     */
    EmailSenderName() {
      if (this.template && this.template.emailSenderName) {
        return this.template.emailSenderName;
      }

      return "";
    },

    /**
     * Get email subject
     */
    EmailSubject() {
      if (this.template && this.template.emailSubject) {
        return this.template.emailSubject;
      }

      return "";
    },

    /**
     * Get SMS sender alias
     */
    SmsSenderAlias() {
      if (this.template && this.template.smsSenderAlias) {
        return this.template.smsSenderAlias;
      }

      return "";
    },

    /**
     * Get SMS sender text
     */
    SmsSenderText() {
      if (this.template && this.template.smsText) {
        return this.template.smsText;
      }

      return "";
    },

    /**
     * Get BS text
     */
    BsText() {
      if (this.template && this.template.bsText) {
        return this.template.bsText;
      }

      return "";
    },

    /**
     * Get MP text
     */
    MpText() {
      if (this.template && this.template.mpText) {
        return this.template.mpText;
      }

      return "";
    },

    /**
     *  Get Template name
     */
    TemplateName() {
      return this.$t(`Communication.Inputs.${this.inputType}`);
    },
  },
};
</script>
