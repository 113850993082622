<template>
  <div class="relative input-container" :class="{ 'display-mode': displayMode }">
    <div
      :class="{
        'w-16 justify-center opacity-30': xl,
      }"
      class="absolute h-full flex"
    >
      <fas
        v-if="computedIcon"
        :class="{
          'animate-spin': loading || spin,
        }"
        :style="{
          width: IconWidth,
          height: 'auto',
        }"
        :icon="computedIcon"
      />
    </div>
    <input
      :type="inputType"
      :value="modelValue"
      @input="updateInput"
      :disabled="disabled || displayMode"
      :placeholder="placeholder"
      class="focus:ring-0 w-full rounded focus:shadow-none transition-all duration-300 border border-solid"
      :class="{
        'border-black border-opacity-10': !displayMode,
        'display-mode border-transparent': displayMode,
        'border-none border-b rounded-none bg-transparent p-1': simple,
        'p-2': !simple && !displayMode,
        'text-lg rounded-full': xl,
        'pl-10': computedIcon && !displayMode && !xl,
        'pl-7': computedIcon && displayMode && !xl,
        'pl-16': computedIcon && xl,
        'shadow-sm border-none focus:shadow  transition-shadow': shadow,
        'w-auto': widthAuto,
      }"
    />
  </div>
</template>

<script>
export default {
  name: "OfInputText",
  props: {
    inputType: {
      type: String,
      default: 'text',
    },
    sm: Boolean,
    xl: Boolean,
    placeholder: String,
    transparent: Boolean,
    disabled: Boolean,
    simple: Boolean,
    shadow: Boolean,
    widthAuto: Boolean,
    modelValue: String,
    displayMode: Boolean,
    icon: {
      type: String,
      default: "",
      required: false,
    },
  },
  methods: {
    updateInput(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  computed: {
    computedIcon() {
      if (this.icon !== "") {
        return this.icon;
      }
      return null;
    },
    IconContainerClass() {
      if (this.xl) {
        return 'w-16 opacity-30 justify-center';
      }

      if (!this.displayMode) {
        return 'w-8 pl-3';
      }

      return 'pl-0';
    },
    IconWidth() {
      if (this.xl) {
        return '20px';
      }
      return '15px';
    },
  }
};
</script>
