export default function () {
  function toastError(error, fallbackErrorMessage = null) {
    let errorMessage;

    if (error === null && fallbackErrorMessage !== null) {
      errorMessage = fallbackErrorMessage;
    } else if (error.response && error.response.status === 503) {
      errorMessage =
        "Service is temporarily unavailable. Please try again later";
    } else if (error.response && error.response.data.errorDescription) {
      errorMessage = error.response.data.errorDescription;
    } else if (fallbackErrorMessage !== null) {
      errorMessage = fallbackErrorMessage;
    } else if (
      error.response &&
      error.response.status === 400 &&
      error.response.data
    ) {
      errorMessage = error.response.data;
    } else {
      errorMessage = "Something went wrong. Please try again later";
    }

    this.$toast.error(errorMessage);
  }

  function toastSuccess(message) {
    this.$toast.success(message);
  }

  return { toastError, toastSuccess };
}
