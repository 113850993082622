<template v-if="this.communication">
  <of-wrap headerNarrow>
    <template v-slot:wrapHeaderTitle>
      {{ this.communication.collectionName }}
    </template>
    <template v-slot:wrapHeaderRight>
      <of-button transparent @click="editCommunication"> Edit </of-button>
    </template>
    <template v-slot:wrapContent>
      <template
        v-if="
          !this.communication.parentGuid &&
          !this.communication.defaultCollection
        "
      >
        <div class="bg-red-500 p-6 rounded-xl">
          <p class="text-center text-lg text-white">
            This collections does not have a parent. Please click
            <of-button sm @click="editCommunication">
              Edit
            </of-button>
            to add a parent collection.
          </p>
        </div>
      </template>
      <div v-else>
        <!-- Event types -->
        <div
          v-for="category in this.eventTypesCategories"
          :key="category"
          :class="{
            'mt-14': category !== 'Signup',
          }"
        >
          <div class="font-bold mb-3">{{ this.headerTrans(category) }}</div>

          <!-- Events in event type -->
          <div
            class="-mx-4"
            v-for="(eventType, index) in eventTypes.filter(
              (x) => x.category === category
            )"
            :key="index"
          >
            <!-- Event in event type-->
            <app-communication-event
              :template="eventTypeTemplate(eventType)"
              @editTemplateEmail="editTemplateEmail"
              @editTemplateScreenHtml="editTemplateScreenHtml"
              @editTemplateSms="editTemplateSms"
              @editTemplateBs="editTemplateBs"
              @editTemplateMp="editTemplateMp"
              :eventType="eventType"
              :communication="this.communication"
            />
          </div>
        </div>
      </div>
    </template>
  </of-wrap>
  <app-event-template-stripo-editor
    @closeModal="closeModal"
    :communication="this.communication"
    :template="this.template"
    v-if="isStripoEditing"
  />
  <app-event-template-email-editor
    @closeModal="closeModal"
    :communication="this.communication"
    :template="this.template"
    @switchToStripo="switchToStripo"
    v-if="isPlainEmailEditing"
  />
  <app-event-template-screen-html-editor
    @closeModal="closeModal"
    @switchToStripo="switchToStripo"
    :communication="this.communication"
    :template="this.template"
    v-if="isScreenHtmlEditing"
  />
  <app-event-template-sms-editor
    @closeModal="closeModal"
    :communication="this.communication"
    :template="this.template"
    v-if="isSmsEditing"
  />
  <app-event-template-bs-editor
    @closeModal="closeModal"
    :communication="this.communication"
    :template="this.template"
    v-if="isBsEditing"
  />
  <app-event-template-mp-editor
    @closeModal="closeModal"
    :communication="this.communication"
    :template="this.template"
    v-if="isMpEditing"
  />
</template>

<script>
import eventTypes from "../../../helpers/communications/eventTypes";
import AppCommunicationEvent from "./events/AppCommunicationEvent.vue";
import AppEventTemplateStripoEditor from "./events/editors/AppEventTemplateStripoEditor.vue";
import AppEventTemplateEmailEditor from "./events/editors/AppEventTemplateEmailEditor.vue";
import AppEventTemplateScreenHtmlEditor from "./events/editors/AppEventTemplateScreenHtmlEditor.vue";
import AppEventTemplateSmsEditor from "./events/editors/AppEventTemplateSmsEditor.vue";
import AppEventTemplateBsEditor from "./events/editors/AppEventTemplateBsEditor.vue";
import AppEventTemplateMpEditor from "./events/editors/AppEventTemplateMpEditor.vue";

export default {
  name: "AppCommunication",
  components: {
    AppCommunicationEvent,
    AppEventTemplateStripoEditor,
    AppEventTemplateEmailEditor,
    AppEventTemplateScreenHtmlEditor,
    AppEventTemplateSmsEditor,
    AppEventTemplateBsEditor,
    AppEventTemplateMpEditor,
  },
  props: {
    communication: {
      type: Object,
      required: true,
    },
  },
  emits: ["editCommunication"],
  data() {
    return {
      template: null,
      isStripoEditing: false,
      isPlainEmailEditing: false,
      isScreenHtmlEditing: false,
      isSmsEditing: false,
      isBsEditing: false,
      isMpEditing: false,
      eventTypes,
    };
  },
  computed: {
    eventTypesCategories() {
      const categories = [];

      for (let i = 0; i < this.eventTypes.length; i += 1) {
        const exist = categories.find(
          (et) => et === this.eventTypes[i].category
        );

        if (!exist) {
          categories.push(this.eventTypes[i].category);
        }
      }

      return categories;
    },
  },
  methods: {
    headerTrans(category) {
      return this.$t(`Communication.${category}`);
    },
    /**
     * Close (all) modals
     */
    closeModal() {
      this.isStripoEditing = false;
      this.isPlainEmailEditing = false;
      this.isScreenHtmlEditing = false;
      this.isSmsEditing = false;
      this.isBsEditing = false;
      this.isMpEditing = false;
    },

    /**
     * Open email modal
     */
    editTemplateEmail(template) {
      this.template = template;

      if (this.template.stripoHtml || this.template.emailHtml === "") {
        this.isStripoEditing = true;
        this.isPlainEmailEditing = false;
      } else {
        this.isPlainEmailEditing = true;
        this.isStripoEditing = false;
      }
    },

    /**
     * Switch email from old to stripo
     */
    switchToStripo(template) {
      this.template = template;

      this.isStripoEditing = true;
      this.isPlainEmailEditing = false;
    },

    /**
     * Open Screen html modal
     */
    editTemplateScreenHtml(template) {
      this.template = template;
      this.isScreenHtmlEditing = true;
    },

    /**
     * Open sms modal
     */
    editTemplateSms(template) {
      this.template = template;
      this.isSmsEditing = true;
    },

    /**
     * Open bs modal
     */
    editTemplateBs(template) {
      this.template = template;
      this.isBsEditing = true;
    },

    /**
     * Open bs modal
     */
    editTemplateMp(template) {
      this.template = template;
      this.isMpEditing = true;
    },

    /**
     * Emit edit communication
     */
    editCommunication() {
      this.$emit("editCommunication");
    },

    /**
     * Get the template for the event
     */
    eventTypeTemplate(eventType) {
      // Do we have templates for this event?
      if (this.communication.templates) {
        // Find the template from event type
        const communicationTemplate = this.communication.templates.find(
          (x) => x.eventType === eventType.eventType
        );

        // Did we get a template for the event? Then return it
        if (communicationTemplate) {
          return communicationTemplate;
        }
      }

      return null;
    },
  },
};
</script>
