<template>
  <div class="tree-list ml-4 -mt-2 border-l border-hover">
    <div
      class="tree-name pt-3 pb-1 text-sm hover:font-bold hover:cursor-pointer"
      :class="{ 'font-bold': communication.selected }"
      @click.prevent="setSelectedCommunication"
    >
      {{ this.communication.collectionName }}
    </div>
    <app-communications-menu-tree
      v-for="(communication, index) in this.communication.communications"
      :communication="communication"
      :key="index"
    />
  </div>
</template>

<script>
export default {
  props: {
    communication: {
      type: Object,
      required: false,
    },
  },
  name: "AppCommunicationsMenuTree",
  emits: ["selectCommunication"],
  methods: {
    /**
     * Set the selected communication by changing the url
     */
    setSelectedCommunication() {
      this.$router.push({
        query: {
          guid: this.communication.communicationCollectionGuid,
        },
      });
    },
  },
};
</script>

<style scoped>
.tree-list .tree-name {
  background: #f8f8f8;
  margin-left: -1px;
}
</style>
