export default function () {
  const now = new Date();
  // eslint-disable-next-line
    const dateFormated = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + ("0" + now.getDate()).slice(-2) + ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();
  const giver = {
    amount: "49,95",
    paymentType: "Recurring",
    paymentTypeTranslated: "Betalingsaftale",
    paymentMethodType: "Card",
    paymentMethodTypeTranslated: "Kort",
    recurringFrequency: "monthly",
    recurringFrequencyTranslated: "Månedligt",
    firstName: "John",
    lastName: "Doe",
    email: "johndoe@mail.com",
    date: dateFormated, // '2021-10-27 14:45:47
    form_id: "1234",
    currencyCode: "DKK",
    msisdn: "4555551234",
    name: "John Doe",
    purposeName: "Donation",
    agreementName: "Donation",
    purposeAccountingCode: "Donation",
    address: {
      address: "Finsensvej 6E",
      city: "Frederiksberg",
      countryCode: "DK",
      postCode: "2000",
    },
  };

  function giverPlaceholderReplacer(text) {
    let retTex = text;
    retTex = retTex.replaceAll("{{amount}}", giver.amount);
    retTex = retTex.replaceAll("{{paymentType}}", giver.paymentType);
    retTex = retTex.replaceAll(
      "{{paymentTypeTranslated}}",
      giver.paymentTypeTranslated
    );
    retTex = retTex.replaceAll(
      "{{paymentMethodType}}",
      giver.paymentMethodType
    );
    retTex = retTex.replaceAll(
      "{{paymentMethodTypeTranslated}}",
      giver.paymentMethodTypeTranslated
    );
    retTex = retTex.replaceAll(
      "{{recurringFrequency}}",
      giver.recurringFrequency
    );
    retTex = retTex.replaceAll(
      "{{recurringFrequencyTranslated}}",
      giver.recurringFrequencyTranslated
    );
    retTex = retTex.replaceAll("{{recurringStart}}", giver.recurringStart);
    retTex = retTex.replaceAll("{{firstName}}", giver.firstName);
    retTex = retTex.replaceAll("{{lastName}}", giver.lastName);
    retTex = retTex.replaceAll("{{email}}", giver.email);
    retTex = retTex.replaceAll("{{date}}", giver.date);
    retTex = retTex.replaceAll("{{currencyCode}}", giver.currencyCode);
    retTex = retTex.replaceAll("{{msisdn}}", giver.msisdn);
    retTex = retTex.replaceAll("{{name}}", giver.name);
    retTex = retTex.replaceAll("{{purposeName}}", giver.purposeName);

    retTex = retTex.replaceAll("{{address}}", giver.address.address);
    retTex = retTex.replaceAll("{{postCode}}", giver.address.postCode);
    retTex = retTex.replaceAll("{{city}}", giver.address.city);
    retTex = retTex.replaceAll("{{countryCode}}", giver.address.countryCode);

    return retTex;
  }

  return { giver, giverPlaceholderReplacer };
}
