// MUTATIONS
const mutations = {
    /**
     * Update copied template
     */
    UPDATE_COPIED_TEMPLATE(state, template) {
        state.copiedTemplateScreen = {
            screenHtml: template.screenHtml,
        };

        state.copiedTemplateEmail = {
            emailHtml: template.emailHtml,
            emailSenderEmail: template.emailSenderEmail,
            emailSenderName: template.emailSenderName,
            emailSubject: template.emailSubject,
        };;

        state.copiedTemplateSMS = {
            smsSenderAlias: template.smsSenderAlias,
            smsText: template.smsText,
        };
    },
};

export default mutations;