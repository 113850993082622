const eventTypes = [
  // SignUp
  {
      eventType: 'DataSetCompleted',
      supported: true,
      category: 'Signup',
      inputs: ['screen', 'email', 'sms'],
  },
  {
      eventType: 'PaymentCompleted',
      supported: true,
      category: 'Signup',
      inputs: ['screen', 'email', 'sms'],
  },
  {
      eventType: 'SubscriptionCompleted',
      supported: true,
      category: 'Signup',
      inputs: ['screen', 'email', 'sms'],
  },
  {
      eventType: 'SubscriptionOneOffCompleted',
      supported: true,
      category: 'Signup',
      inputs: ['screen', 'email', 'sms'],
  },

  // Ongoing
  {
      eventType: 'PaymentRecurringPending',
      supported: true,
      category: 'Ongoing',
      inputs: ['sms', 'mp', 'bs'],
  },
  {
      eventType: 'PaymentRecurringCompleted',
      supported: true,
      category: 'Ongoing',
      inputs: ['email', 'sms'],
  },

  // Changes
  {
      eventType: 'PaymentRefunded',
      supported: true,
      category: 'Changes',
      inputs: ['email', 'sms'],
  },
  {
      eventType: 'AgreementUpdated',
      supported: true,
      category: 'Changes',
      inputs: ['email', 'sms'],
  },
  {
      eventType: 'AddOnCreated',
      supported: false,
      category: 'Changes',
      inputs: ['email', 'sms'],
  },
  // {
  //     eventType: 'SubscriptionUpdated',
  //     name: 'Subscription updated',
  //     supported: true,
  //     description: 'When a subscription has been updated by creditor',
  //     category: 'Changes',
  //     inputs: ['email', 'sms'],
  // },
  // {
  //     eventType: 'SubscriptionInactive',
  //     name: 'Subscription deactivated',
  //     supported: true,
  //     description: 'When a subscription has been deactivated by creditor',
  //     category: 'Changes',
  //     inputs: ['email', 'sms'],
  // },
  // {
  //     eventType: 'SubscriptionOnHold',
  //     name: 'Subscription on hold',
  //     supported: true,
  //     description: 'When a subscription has been put on hold by creditor',
  //     category: 'Changes',
  //     inputs: ['email', 'sms'],
  // },
  // {
  //     eventType: 'SubscriptionRestarted',
  //     name: 'Subscription restarted',
  //     supported: true,
  //     description: 'When a subscription has been restarted by creditor',
  //     category: 'Changes',
  //     inputs: ['email', 'sms'],
  // },

  // Retention
  {
      eventType: 'PaymentMethodNeedsRenewal',
      supported: true,
      category: 'Retention',
      inputs: ['email', 'sms'],
  },
  {
      eventType: 'PaymentMethodCancelled',
      supported: true,
      category: 'Retention',
      inputs: ['email', 'sms'],
  },
  {
      eventType: 'PaymentRecurringRejected',
      supported: false,
      category: 'Retention',
      inputs: ['email', 'sms'],
  },
  {
      eventType: 'PaymentRecurringFailed',
      supported: true,
      category: 'Retention',
      inputs: ['email', 'sms'],
  },
  // {
  //     eventType: 'SubscriptionReachedNonPayment',
  //     name: 'Subscription fail to charge repeatedly',
  //     supported: true,
  //     description: 'When a subscription with required payment has failed to charge several times',
  //     category: 'Retention',
  //     inputs: ['email', 'sms'],
  // },
];

export default eventTypes;