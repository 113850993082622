<template>
  <of-modal narrow @closeModal="closeModal">
    <!-- Modal header title -->
    <template v-slot:modalHeaderTitle>
      <span class="opacity-50">
        {{ this.communication.collectionName }} / {{ this.eventTypeName }}
      </span>
      / SMS
    </template>

    <!-- Modal header right -->
    <template v-slot:modalHeaderRight>
      <of-button
        sm
        transparent
        class="mr-6"
        :icon="'code'"
        @click="this.showVariables = !this.showVariables"
      >
        Variables
      </of-button>
      <of-button
        @click="saveSms"
        :loading="this.savingEmail"
        :icon="this.saveEmailButtonIcon"
        sm
        class="ml-auto mr-0"
      >
        {{ this.saveButtonText }}
      </of-button>
    </template>

    <!-- Modal content -->
    <template v-slot:modalContent>
      <div class="preview-container-fluid relative h-full">
        <p>Sender name</p>
        <of-input-text v-model="this.smsSenderAlias" />
        <p class="mt-6">Text</p>
        <of-input-textarea
          v-model="this.smsText"
          style="min-height: 300px"
          @keyup="checkCount"
        />
        <app-variables-modal
          v-if="this.showVariables"
          @closeVariablesModal="closeVariablesModal"
        >
        </app-variables-modal>
        <div class="grid grid-cols-2">
          <div class="col-span-1">
            <div v-if="this.smsText" class="opacity-60 text-xs">
              <p>
                {{ this.charCount }}/{{ this.maxChars }} char.
                <em
                  >(
                  {{ this.smsCount }}
                  SMS</em
                >)
              </p>
            </div>
          </div>
          <div class="col-span-1">
            <p class="mt-6">Send test SMS</p>
            <div class="control-input inline-input">
              <input
                type="tel"
                class="uik-input__input"
                placeholder="Insert your phone number"
                v-model="phoneNumberTestingTextMessage"
              />
              <input
                type="button"
                role="button"
                class="uik-btn__base btn btn-secondary"
                value="Send test"
                v-on:click="testTextMessage"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </of-modal>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { mapActions } from "vuex";
import axios from "axios";
import md5 from "js-md5";
import AppVariablesModal from "../../AppVariablesModal.vue";
import toast from "../../../../../helpers/toast";
import eventTypes from "../../../../../helpers/communications/eventTypes";

export default {
  name: "AppEventTemplateSmsEditor",
  components: {
    AppVariablesModal,
  },
  props: {
    communication: {
      type: Object,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      savingEmail: false,
      saveEmailButtonText: "Save",
      saveEmailButtonIcon: "",
      showVariables: false,
      smsSenderAlias: this.template.smsSenderAlias,
      smsText: this.template.smsText,
      phoneNumberTestingTextMessage: "",
      errorMessage: "",
      charCount: 0,
      maxChars: 0,
      smsCount: 0,
    };
  },
  setup() {
    // Setup toast notification from '/src/helpers/toast.js'
    const { toastError, toastSuccess } = toast();
    return { toastError, toastSuccess };
  },
  computed: {
    /**
     * Save button text
     */
    saveButtonText() {
      if (this.savingEmail) {
        return "Saving";
      }

      return this.saveEmailButtonText;
    },

    /**
     * Get event type name
     */
    eventTypeName() {
      return eventTypes.find((x) => x.eventType === this.template.eventType)
        .name;
    },
  },
  emits: ["closeModal"],
  mounted() {
    this.checkCount();
  },
  methods: {
    // Map actions (./store/modules/communications/templates/actions.js)
    ...mapActions({ updateTemplate: "communications/templates/update" }),

    /**
     * Save
     */
    saveSms() {
      // Start spinner and button text
      this.savingEmail = true;

      // The values to update
      const updateInTemplate = {
        communicationCollectionGuid: this.template.communicationCollectionGuid,
        communicationTemplateGuid: this.template.communicationTemplateGuid,
        smsText: this.smsText,
        smsSenderAlias: this.smsSenderAlias,
      };

      // Call update template action
      this.updateTemplate(updateInTemplate)
        .then(() => {
          // Notify user of success
          this.toastSuccess("SMS saved");

          // Stop spinner and button text
          this.savingEmail = false;
          this.saveEmailButtonText = "Saved!";
          this.saveEmailButtonIcon = "check";

          // Change button text back to just save after 3 sec
          setTimeout(() => {
            this.saveEmailButtonText = "Save";
            this.saveEmailButtonIcon = "";
          }, 3000);
        })
        .catch((error) => {
          this.toastError(error);
        });
    },

    /**
     * Send test SMS
     */
    testTextMessage() {
      //  Validate
      this.validateTestIdleSMS();

      // Return if error
      if (this.errorMessage) {
        return;
      }

      // Prepare data
      const body = JSON.stringify({
        msisdn: this.phoneNumberTestingTextMessage,
        smsText: this.smsText,
      });

      // Post
      axios
        .post(
          `${this.$store.state.apiUrl}/communicationTemplate/TestSms`,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$store.state.apiToken,
              requestMD5: md5(body),
            },
          }
        )
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.toastSuccess("SMS test sent");
          } else if (response.status === 404) {
            this.toastSuccess("We were not able to send the sms.");
          }
        })
        .catch((error) => {
          this.toastError(error);
        });
    },

    /**
     * Validate SMS
     */
    validateTestIdleSMS() {
      this.errorMessage = "";

      if (!this.smsText) {
        this.errorMessage = "Please insert a message to test";
      } else if (!this.phoneNumberTestingTextMessage) {
        this.errorMessage = "Please insert a phone number";
      } else if (this.phoneNumberTestingTextMessage.length === 8) {
        this.phoneNumberTestingTextMessage = `45${this.phoneNumberTestingTextMessage}`;
      }

      if (
        !this.errorMessage &&
        this.phoneNumberTestingTextMessage.length !== 10
      ) {
        this.errorMessage =
          "Your phone number does not seem Danish. Please review";
      }

      if (this.errorMessage) {
        console.log(this.errorMessage);
        // this.$root.errorResponse(this.errorMessage);
      }
    },

    /**
     * Return length of text message
     * @param {string} string
     */
    countTextMessages(string) {
      if (!string) {
        return "";
      }
      return Math.ceil(string.toString().length / 160);
    },

    /**
     * Return length of text message
     * @param {string} string
     */
    checkCount() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const body = JSON.stringify({
          text: this.smsText,
        });

        axios
          .post("https://gateway.smscph.dk/SmsCount", body, {
            headers: {
              "Content-Type": "application/json",
              requestMD5: md5(body),
            },
          })
          // eslint-disable-next-line
          .then(response => {
            this.charCount = response.data.charCount;
            this.maxChars = response.data.maxChars;
            this.smsCount = response.data.smsCount;
          })
          // eslint-disable-next-line
          .catch(error => {
            this.errorMessage = this.$root.handleErrorResponse(error);
          });
      }, 500);
    },

    /**
     * Close modal
     */
    closeModal() {
      this.$emit("closeModal");
    },

    /**
     * Close variables modal
     */
    closeVariablesModal() {
      this.showVariables = false;
    },
  },
};
</script>
