/* eslint no-shadow: ["error", { "allow": ["getters"] }] */
// GETTERS
const getters = {
    /**
     * Get communications
     */
    getCommunications: (state) => state.communications,

    /**
     * Get communication
     */
    getCommunication: (state, getters) => (collectionGuid) =>
        getters.getCommunications.find((x) => x.communicationCollectionGuid === collectionGuid),
};

export default getters;
