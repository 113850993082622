<template>
    <of-row
        cols="4"
        hover
        :background="BackgroundColor"
        @showBody="showBody"
    >
        <slot>
            <div>{{ subscription.state }}</div>
            <div>{{ new Date(subscription.createdTs).toLocaleString() }}</div>
            <div>{{ new Date(subscription.nextDueDate).toLocaleString() }}</div>
            <div>{{ subscription.paymentMethodType }}</div>
        </slot>
        <template v-slot:body>
            <div
                class="m-4 ml-3 mt-0  shadow rounded bg-white"
                :class="{ 'grid grid-cols-4': loadContact }"
            >
                <div v-if="loadContact" class="p-4">
                    <app-contact-info v-if="contact" :contact="contact" />
                </div>
                <div
                    class="transition-all"
                    :class="[ColSpan, Padding]"
                >
                
                    <h2 class="mb-2">Overview</h2>
                    <div class="rounded overflow-hidden border">
                        <of-row hover noOverflow hasButton>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Status`) }}</div>
                            <div class="col-span-7">{{ this.subscription.state }}</div>
                            <div class="col-span-2 text-right">
                                <of-button v-if="this.subscription.state == 'Active'" archive sm rowButton>Change</of-button>
                            </div>
                            <template v-slot:body>
                                <div class="grid grid-cols-12 gap-4 justify-end">
                                    <div class="col-span-3"></div>
                                    <div class="col-span-9 pr-4 pb-4">
                                        <div class="shadow rounded bg-white p-4">
                                            <select class="rounded border p-2">
                                                <option>Active</option>
                                                <option>Hold</option>
                                                <option>Cancel</option>
                                            </select>
                                            <of-input-textarea class="mt-4" placeholder="Reason" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </of-row>
                        <of-row hover noOverflow hasButton>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Amount`) }}</div>
                            <div class="col-span-7">{{ TotalAmount }}</div>
                            <div class="col-span-2 text-right">
                                <of-button v-if="this.subscription.state == 'Active'" archive sm rowButton>Change</of-button>
                            </div>
                            <template v-slot:body>
                                <div class="grid grid-cols-12 gap-4 justify-end">
                                    <div class="col-span-3"></div>
                                    <div class="col-span-9 pr-4 pb-4">
                                        <div class="shadow rounded bg-white p-4">
                                            <of-input-text placeholder="Amount" inputType="number" :modelValue="this.agreement.amountTotal" />
                                            <of-input-text placeholder="VAT percentage" inputType="number" :modelValue="this.agreement.amountVat" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </of-row>
                        <of-row hover noOverflow hasButton>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Frequency`) }}</div>
                            <div class="col-span-7">{{ ScheduleType }}</div>
                            <div class="col-span-2 text-right">
                                <of-button v-if="this.subscription.state == 'Active'" archive sm rowButton>Change</of-button>
                            </div>
                        </of-row>
                        <of-row hover noOverflow hasButton>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Created`) }}</div>
                            <div class="col-span-6">{{ new Date(subscription.originTs).toLocaleString() }}</div>
                            <div class="col-span-3 text-right">
                                <of-button v-if="this.subscription.state == 'Active'" archive sm rowButton>Resend confirmation</of-button>
                            </div>
                        </of-row>
                        <of-row borderBottom>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.NextDueDate`) }}</div>
                            <div class="col-span-9">{{ new Date(subscription.nextDueDate).toLocaleString() }}</div>
                        </of-row>
                        <div class="h-8"></div>
                        <of-row>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Name`) }}</div>
                            <div class="col-span-9">{{ AgreementName }}</div>
                        </of-row>
                        <of-row>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Description`) }}</div>
                            <div class="col-span-9">{{ AgreementDescription }}</div>
                        </of-row>
                        <of-row>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.PurposeAccountCode`) }}</div>
                            <div class="col-span-9">{{ PurposeAccountingCode }}</div>
                        </of-row>
                        <of-row borderBottom>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Agreement.AgreementType`) }}</div>
                            <div class="col-span-9">{{ AgreementType }}</div>
                        </of-row>
                        
                        <div class="h-8"></div>
                        <of-row>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.PaymentType`) }}</div>
                            <div class="col-span-9">{{ PaymentMethodType }}</div>
                        </of-row>
                        <of-row>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.CardType`) }}</div>
                            <div class="col-span-9">{{ PaymentMethodAccountingCode }}</div>
                        </of-row>
                        <template v-if="payment && payment.metaData">
                            <of-row v-if="payment.metaData.card && payment.metaData.card.cardNumber">
                                <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.CardNumber`) }}</div>
                                <div class="col-span-9">{{ payment.metaData.card.cardNumber }}</div>
                            </of-row>
                        </template>
                        <of-row borderBottom>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.TransactionNumber`) }}</div>
                            <div class="col-span-9">{{ PaymentGatewayTransactionId }}</div>
                        </of-row>
                        <!-- <div class="h-12"></div>
                        <of-row>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.Gateway`) }}</div>
                            <div class="col-span-9">{{ payment.paymentGatewayProvider }}</div>
                        </of-row>
                        <of-row borderBottom>
                            <div class="col-span-3 font-bold">{{ this.$t(`Global.Payment.PaymentGatewayTransactionId`) }}</div>
                            <div class="col-span-9">{{ payment.paymentGatewayTransactionId }}</div>
                        </of-row> -->
                    </div>
                    <div class="mt-10">
                        <app-payment-list :payments="payments" />
                    </div>
                </div>
            </div>
        </template>
    </of-row>
</template>

<script>
import axios from "axios";
import AppContactInfo from "../contacts/AppContactInfo.vue";
import AppPaymentList from "../payments/AppPaymentList.vue";
import OfInputTextarea from '../../shared/input/OfInputTextarea.vue';
import OfInputText from '../../shared/input/OfInputText.vue';
import { mapState } from 'vuex';
import visuals from "./../../../libs/visuals";

export default {
    name: "AppSubscriptionRow",
    components: {
        AppContactInfo,
        AppPaymentList,
        OfInputTextarea,
        OfInputText,
    },
    data() {
        return {
            contact: null,
            agreement: null,
            payment: null,
            payments: null,
            bodyShown: false,
        };
    },
    props: {
        subscription: {
            type: Object,
            required: true,
        },
        loadContact: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(["visual"]),
        ColSpan() {
            if (this.loadContact) {
                return 'col-span-3'
            }
            return '';
        },
        Padding() {
            return visuals[this.visual].container.padding;
        },
        AgreementName() {
            if (this.agreement) {
                return this.agreement.name;
            }

            return this.renderText;
        },
        AgreementDescription() {
            if (this.agreement) {
                return this.agreement.description;
            }

            return this.renderText;
        },
        ScheduleType() {
            if (this.agreement) {
                return this.agreement.scheduleType;
            }

            return this.renderText;
        },
        PurposeAccountingCode() {
            if (this.agreement) {
                return this.agreement.purposeAccountingCode;
            }

            return this.renderText;
        },
        AgreementType() {
            if (this.agreement) {
                return this.agreement.agreementType;
            }

            return this.renderText;
        },
        PaymentMethodType() {
            if (this.payment) {
                return this.payment.paymentMethodType;
            }

            return this.renderText;
        },
        PaymentMethodAccountingCode() {
            if (this.payment) {
                return this.payment.paymentMethodAccountingCode;
            }

            return this.renderText;
        },
        PaymentGatewayTransactionId() {
            if (this.payment) {
                return this.payment.paymentGatewayTransactionId;
            }

            return this.renderText;
        },
        TotalAmount() {
            if (this.agreement) {
                const formatter = new Intl.NumberFormat("da", {
                    style: "currency",
                    currency: this.agreement.currencyCode,
                });

                return formatter.format(this.agreement.amountTotal);
            }

            return this.renderText();
        },
        BackgroundColor() {
            switch (this.subscription.state) {
                case 'Refunded':
                case 'Cancelled':
                    return 'purple';
                case 'Pending':
                    return 'blue';
                case 'Rejected':
                case 'Failed':
                    return 'red';
                case 'Active':
                    return 'green';
            }

            return '';
        },
    },
    methods: {
        renderText(text) {
            if (text) {
                return text;
            }

            return 'no data';
        },
        showBody() {
            if (!this.bodyShown) {
                this.bodyShown = true;

                // Agreement
                axios.get(`${this.$store.state.OFconfig.api_payment_engine_url}/agreement/${this.subscription.agreementGuid}`, {
                    headers: {
                        Authorization: this.$store.state.OFconfig.api_payment_engine_token,
                    }
                    })
                    .then((response) => {
                        this.agreement = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });

                // Payment
                axios.get(`${this.$store.state.OFconfig.api_payment_engine_url}/paymentMethod/${this.subscription.paymentMethodGuid}`, {
                    headers: {
                        Authorization: this.$store.state.OFconfig.api_payment_engine_token,
                    }
                })
                .then((response) => {
                    this.payment = response.data;
                })
                .catch(error => {
                    console.log(error);
                });

                // Payments
                axios.get(`${this.$store.state.OFconfig.api_payment_engine_url}/subscription/${this.subscription.subscriptionGuid}/payments`, {
                    headers: {
                        Authorization: this.$store.state.OFconfig.api_payment_engine_token,
                    }
                })
                .then((response) => {
                    this.payments = response.data;
                })
                .catch(error => {
                    console.log(error);
                });

                // Load contact, if needed
                if (this.loadContact) {
                    this.bodyShown = true;
                        
                    axios.get(`${this.$store.state.OFconfig.api_payment_engine_url}/contact/${this.subscription.contactGuid}`, {
                        headers: {
                            Authorization: this.$store.state.OFconfig.api_payment_engine_token,
                        }
                        })
                        .then((response) => {
                            this.contact = response.data;
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            }
        },
    }
}
</script>