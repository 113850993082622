<template>
  <!-- <div
    class="row header text-gray-400 grid gap-4 py-1 py-1 first:p-5 text-sm font-semibold"
    :class="GridCols"
  > -->
  <div
    class="row header text-gray-400 grid gap-4 py-1 py-2 first:p-5 text-sm font-semibold"
    :class="GridCols"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "OfRowHeader",
  props: {
    cols: {
      type: String,
      default: "12",
    },
  },
  computed: {
    GridCols() {
      if (parseInt(this.cols, 10) > 12) {
        return "grid-cols-12";
      }

      return `grid-cols-${this.cols}`;
    },
  },
};
</script>
