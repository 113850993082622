export default {
    default: {
      row: {
          padding: 'py-2',
      },
      container: {
          padding: 'p-10',
      }
    },
    compact: {
      row: {
          padding: 'py-1',
      },
      container: {
          padding: 'p-4',
      }
  },
}