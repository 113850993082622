<template>
    <div class="relative">
        <div class="absolute right-0 top-1 text-sm cursor-pointer" @click="displayMode = !displayMode">Edit</div>
        <div class="mb-3">
            <h1 class="text-lg font-bold">{{ contact.name }}</h1>
            <of-input-text icon="suitcase" placeholder="No company" :displayMode="displayMode" :modelValue="contact.companyName" />
        </div>
        <of-input-text icon="envelope" placeholder="No email" :displayMode="displayMode" :modelValue="contact.email" />
        <of-input-text icon="phone" placeholder="No phone" :displayMode="displayMode" :modelValue="this.formatPhoneNo(contact.msisdn, contact.countryCode)" />
        <div class="mt-3" v-if="RenderAddress != ''" v-html="RenderAddress"></div>
    </div>
</template>

<script>

export default {
    name: "AppContactInfo",
    data() {
        return {
            displayMode: true,
        };
    },
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    computed: {
        RenderAddress() {
            let retHtml = '';

            if (this.contact.address) {
                retHtml += `<p>${this.contact.address}</p>`;
            }

            if (this.contact.postCode || this.contact.city || this.contact.countryCode) {
                retHtml += '<p>';

                if (this.contact.postCode) {
                    retHtml += this.contact.postCode;

                    if (this.contact.city) {
                        retHtml += ` ${this.contact.city}`;

                        if (this.contact.countryCode) {
                            retHtml += ` ${this.contact.countryCode}`;
                        }
                    } else if (this.contact.countryCode) {
                        retHtml += `-${this.contact.countryCode}`;
                    }
                }

                retHtml += '</p>';
            }

            return retHtml;
        }
    },
    methods: {
        formatPhoneNo(msisdn, countryCode) {
            if (!this.displayMode) {
                return msisdn;
            }

            if (countryCode == 'DK') {
                if (msisdn.length == 10) {
                return `+45 ${msisdn.substring(2,6)} ${msisdn.substring(6,10)}`;
                }
            }

            return msisdn;
        },
    }
}
</script>