<template>
    <h2 class="mb-2">Payments</h2>
    <div class="rounded overflow-hidden" :class="Shadow">
        <div v-if="!payments">Loading</div>
        <template v-else>
            <app-row-header cols="6">
                <div>{{ this.$t(`Global.State`) }}</div>
                <div>{{ this.$t(`Global.Created`) }}</div>
                <div>{{ this.$t(`Global.Payment.PurposeAccountCode`) }}</div>
                <!-- <div>{{ this.$t(`Global.Payment.Gateway`) }}</div> -->
                <div>{{ this.$t(`Global.Payment.TransactionNumber`) }}</div>
                <div class="text-right">{{ this.$t(`Global.Amount`) }}</div>
                <div>{{ this.$t(`Global.Payment.PaymentType`) }}</div>
            </app-row-header>
            <app-payment-row
                v-for="(payment, index) in payments"
                :key="index"
                :payment="payment"
                :loadContact="loadContact"
            />
        </template>
    </div>
</template>

<script>
import AppPaymentRow from './AppPaymentRow.vue';
import AppRowHeader from './../../shared/layout/OfRowHeader.vue';

export default {
    name: "AppPaymentList",
    components: {
        AppPaymentRow,
        AppRowHeader,
    },
    props: {
        payments: {
            type: Object,
            required: true,
        },
        shadow: {
            type: String,
            default: '',
        },
        loadContact: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        Shadow() {
            if (this.shadow) {
                return `${this.shadow} bg-white`;
            }

            return 'border';
        },
    },
}
</script>